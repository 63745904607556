import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { CalendarIcon } from '@heroicons/react/24/outline';

export default function FlyoutMenu({ chartMenuFilters }) {
  return (
    <Popover className="relative">
      <Popover.Button className="px-1 py-1 border-2 border-slate-200 rounded-lg focus:outline-none inline-flex items-center gap-x-1 text-sm font-semibold leading-6">
        <CalendarIcon className="h-5 w-5 text-blue-500"/>
        <span className='text-gray-500'>Periodo</span>
        <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-2 flex -translate-x-1/2 px-4">
          <div className="flex-auto overflow-hidden rounded-md bg-white text-sm leading-6 shadow-xl ring-1 ring-gray-900/5">
            <div className="p-2">
              {chartMenuFilters.map((item) => (
                <div key={item.name} className="group relative flex items-center gap-x-2 rounded-lg p-1 hover:bg-gray-50">
                  <div className="flex h-8 w-14 flex-none items-center justify-center rounded-sm bg-gray-50 group-hover:bg-white">
                    <div className="text-gray-600 font-bold group-hover:text-blue-600" aria-hidden="true">{item.period}</div>
                  </div>
                  <div>
                    <a href={item.href} className="font-semibold text-gray-900">
                      {item.name}
                      <span className="absolute inset-0" />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
