import { Tooltip } from 'react-tooltip';
import {
    QuestionMarkCircleIcon,
    PencilSquareIcon
} from '@heroicons/react/24/solid';

export default function ProfileHeader({ user }) {

    return (
        <>
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap border-b border-gray-200 py-5">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <img
                            className="h-12 w-12 rounded-full"
                            src={user.imageUrl}
                            alt=""
                        />
                    </div>
                    <div className="ml-4">
                        <h3 className="text-xl text-[#050624] font-bold leading-6">{user.name}</h3>
                        <p className="text-sm text-gray-500">
                            <a href="#">{user.email}</a>
                        </p>
                    </div>
                </div>
                <div className="flex flex-shrink-0">
                    <a
                        href='#'
                        className="relative inline-flex items-center text-sm font-semibold text-gray-900"
                    >
                        <PencilSquareIcon className="-ml-0.5 mr-1.5 h-6 w-6 text-gray-400 hover:text-blue-500 ease-in-out duration-200" aria-hidden="true" />
                    </a>
                    <a
                        href='#'
                        className="relative ml-3 inline-flex items-center text-sm font-semibold text-gray-900"
                    >
                        <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="userHelpTooltip" data-tooltip-content="Ayuda" />
                        <Tooltip id="userHelpTooltip" place='left' />
                    </a>
                </div>
            </div>

            <div className='flex items-center pt-5'>
                <h3 className='text-xl text-[#050624] font-bold'>Tu perfil es:</h3>
                <div className='ml-2 rounded-md bg-blue-500 text-white py-1 px-4'>{user.riskProfile}</div>
            </div>
        </>
    )
}