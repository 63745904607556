import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

const investmentObjectives = [
    {
        id: 1,
        objective: 'Jubilación',
        description: 'Invierte para tu retiro',
        form: 'Jubilacion',
        imageUrl:
            require('../assets/objective/1.jpg'),
    },
    {
        id: 2,
        objective: 'Otros',
        description: 'Destina recursos para tener más tranquilidad en el futuro',
        form: 'Otros',
        imageUrl:
            require('../assets/objective/2.jpg'),
    },
    {
        id: 3,
        objective: 'Vivienda',
        description: 'Ahorra para tu primera vivienda, una nueva, remodelaciones o la tu casa de descanso',
        form: 'Vivienda',
        imageUrl:
            require('../assets/objective/3.jpg'),
    },
    {
        id: 4,
        objective: 'Educación',
        description: 'Date la oportunidad a ti o a tus seres queridos de crecer y cumplir sus sueños',
        form: 'Educacion',
        imageUrl:
            require('../assets/objective/4.jpg'),
    },
    {
        id: 5,
        objective: 'Protección Familia',
        description: 'Prepárate para aumentos en gastos como el cuidado de niños o mayores',
        form: 'ProteccionFamilia',
        imageUrl:
            require('../assets/objective/5.jpg'),
    },
    {
        id: 6,
        objective: 'Viajes',
        description: 'Empieza a ahora a programar las vacaciones de tus sueños',
        form: 'Viajes',
        imageUrl:
            require('../assets/objective/6.jpg'),
    },
    {
        id: 7,
        objective: 'Ocasiones',
        description: 'Para esos momentos únicos como aniversarios, matrimonios, graduaciones',
        form: 'Ocasiones',
        imageUrl:
            require('../assets/objective/7.jpg'),
    },
    {
        id: 8,
        objective: 'Compras relevantes',
        description: 'Ahorra para compras importantes como un auto o para darte un gusto',
        form: 'ComprasRelevantes',
        imageUrl:
            require('../assets/objective/8.jpg'),
    },
]

export default function Objectves() {
    const [selectedObjective, setSelectedObjective] = useState('');

    const handleObjectiveSelection = (objective) => {
        setSelectedObjective(objective);
    };

    return (
        <main className="lg:pr-64">
            <div className="xl:pl-72">
                <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
                    <div className="py-16 sm:py-16 lg:py-16">

                        <div className="text-center">
                            <div className="text-left mt-2 relative left-[-15px]">
                                <div className="inline-flex space-x-6">
                                    <Link
                                        to={'/'}>
                                        <ChevronLeftIcon className='text-blue-500 h-12 w-12' />
                                    </Link>
                                </div>
                            </div>
                            <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                ¿Cuál es el fin de tu inversión?
                            </h1>
                            <div className='mx-auto rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 h-1 w-24 mt-5'></div>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Elije la alternativa que mas se ajusta a tu objetivo
                            </p>

                            <div className="mt-12 grid grid-cols-1 gap-4 sm:grid-cols-2">
                                {investmentObjectives.map((objective) => (
                                    <div
                                        key={objective.id}
                                        className="relative flex items-start space-x-3 rounded-lg border border-gray-300 group bg-white focus-within:bg-blue-500 hover:bg-blue-500 focus-within:drop-shadow-xl hover:drop-shadow-xl ease-in-out duration-200"
                                    >
                                        <div className="flex-shrink-0">
                                            <img className="h-32 w-28 rounded-tl-lg rounded-bl-lg" src={objective.imageUrl} alt="" />
                                        </div>
                                        <div className="min-w-0 flex-1 p-2 flex justify-start">
                                            <button onClick={() => handleObjectiveSelection(objective.form)} className="text-left focus:outline-none">
                                                <span className="absolute inset-0" aria-hidden="true" />
                                                <p className="text-xl font-medium font-bold text-blue-500 group-focus-within:text-white group-hover:text-white mb-2 ease-in-out duration-200">{objective.objective}</p>
                                                <p className="text-sm text-gray-500 group-focus-within:text-white group-hover:text-white ease-in-out duration-200">{objective.description}</p>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="mt-10 pt-10 border-t border-gray-900/10 flex items-center justify-center gap-x-6">
                                <Link
                                    to={`/definicionObjetivo/${selectedObjective}`}
                                    className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-8 py-4 text-lg font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                >
                                    Siguiente
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
};
