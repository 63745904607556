import { Tooltip } from 'react-tooltip';
import {
    PlayCircleIcon
} from '@heroicons/react/24/outline';
import {
    QuestionMarkCircleIcon
} from '@heroicons/react/24/solid';

export default function OtherInvestmentAssets() {

    return (
        <div className="border-b border-gray-200 py-5">
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="flex items-center">
                    <div className="mb-4">
                        <h3 className="text-xl text-[#050624] font-bold leading-6">Otro activos</h3>
                    </div>
                </div>
                <div className="flex flex-shrink-0">
                    <a
                        href='#'
                        className="relative ml-3 inline-flex items-center text-sm font-semibold text-gray-900"
                    >
                        <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="assetsHelpTooltip" data-tooltip-content="Ayuda" />
                        <Tooltip id="assetsHelpTooltip" place='left'/>
                    </a>
                </div>
            </div>

            <div className="pt-16 pb-8">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Agrega otros activos
                    </h2>
                    <p className="mx-auto mt-2 max-w-xl text-lg leading-8 text-gray-600">
                        y consolida tus inversiones
                    </p>
                    <PlayCircleIcon className="mx-auto mt-5 h-40 w-40 text-gray-400" />
                </div>
            </div>
            <div className="flex justify-end mb-5">
                <a
                    href="#"
                    className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-5 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                    Agregar Activo
                </a>
            </div>
        </div>
    )
}