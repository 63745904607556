import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import InvestmentSurveyStep1 from './investmentSurveySteps/investmentSurveyStep1';
import InvestmentSurveyStep2 from './investmentSurveySteps/investmentSurveyStep2';
import InvestmentSurveyStep3 from './investmentSurveySteps/investmentSurveyStep3';
import InvestmentSurveyStep4 from './investmentSurveySteps/investmentSurveyStep4';
import InvestmentSurveyStep5 from './investmentSurveySteps/investmentSurveyStep5';
import InvestmentSurveyStep6 from './investmentSurveySteps/investmentSurveyStep6';
import InvestmentSurveyStep7 from './investmentSurveySteps/investmentSurveyStep7';

const steps = [
    { name: 'Info general', href: '#', status: 'complete', component: Step1Form },
    { name: 'Conyugue', href: '#', status: 'complete', component: Step2Form },
    { name: 'Contacto', href: '#', status: 'current', component: Step3Form },
    { name: 'Datos cuenta', href: '#', status: 'upcoming', component: Step4Form },
    { name: 'FATCA y PEP', href: '#', status: 'upcoming', component: Step5Form },
    { name: 'Términos y condiciones', href: '#', status: 'upcoming', component: Step6Form },
    { name: 'Firma digital', href: '#', status: 'upcoming', component: Step7Form }
];


function Step1Form() {
    // Implementa el formulario para el paso 1
    return <InvestmentSurveyStep1/>;
}

function Step2Form() {
    // Implementa el formulario para el paso 2
    return <InvestmentSurveyStep2/>;
}

function Step3Form() {
    // Implementa el formulario para el paso 1
    return <InvestmentSurveyStep3/>;
}

function Step4Form() {
    // Implementa el formulario para el paso 2
    return <InvestmentSurveyStep4/>;
}

function Step5Form() {
    // Implementa el formulario para el paso 1
    return <InvestmentSurveyStep5/>;
}

function Step6Form() {
    // Implementa el formulario para el paso 2
    return <InvestmentSurveyStep6/>;
}

function Step7Form() {
    // Implementa el formulario para el paso 2
    return <InvestmentSurveyStep7/>;
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function InvestmentSurvey() {
    const [currentStep, setCurrentStep] = useState(0);

    const handleNextStep = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handlePrevStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleFinish = () => {
        // Aquí puedes implementar la lógica para finalizar el proceso de encuesta
        console.log('Encuesta finalizada');
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <nav aria-label="Progress" className='sm:mx-auto sm:w-full flex justify-center'>
                <ol role="list" className="flex items-center">
                    {steps.map((step, stepIdx) => {
                        let status;
                        if (stepIdx < currentStep) {
                            status = 'complete';
                        } else if (stepIdx === currentStep) {
                            status = 'current';
                        } else {
                            status = 'upcoming';
                        }
                        return (
                            <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pr-4 sm:pr-8' : '', 'relative')}>
                                {status === 'complete' ? (
                                    <>
                                            <span className="text-gray-900 font-medium text-lg">{step.name}</span>
                                    </>
                                ) : status === 'current' ? (
                                    <>
                                            <span className="text-blue-500 font-medium text-lg">{step.name}</span>
                                    </>
                                ) : (
                                    <>
                                            <span className="text-gray-300 font-medium text-lg">{step.name}</span>
                                    </>
                                )}
                            </li>
                        );
                    })}
                </ol>
            </nav>
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                {React.createElement(steps[currentStep].component)}
                <div className=' flex justify-between'>

                {currentStep === 0 && (
                    <button className="rounded-md px-5 py-2 text-sm font-semibold bg-gray/5 cursor-auto text-gray-300 ring-1 ring-gray-300">
                        Anterior
                    </button>
                )}
                {currentStep > 0 && (
                    <button className="rounded-md px-5 py-2 text-sm font-semibold text-blue-500 ring-1 ring-blue-500" onClick={handlePrevStep}>
                        Anterior
                    </button>
                )}
                {currentStep < steps.length - 1 && (
                    <button className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-5 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" onClick={handleNextStep}>
                        Siguiente
                    </button>
                )}
                {currentStep === steps.length - 1 && (
                    <Link to="/resultadoEncuestaI" className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-5 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" onClick={handleFinish}>
                        Finalizar
                    </Link>
                )}
                </div>
            </div>
        </div>
    );
}

