import { Tooltip } from 'react-tooltip';
import {
    QuestionMarkCircleIcon
} from '@heroicons/react/24/solid';

export default function FamilyProtectionForm() {

    return (
        <form>
            <div className="space-y-12 sm:space-y-16">
                <div>
                    <div className="mt-10 space-y-8 border-b border-gray-500/10 pb-12 sm:space-y-0 sm:border-t sm:pb-0">
                        <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                            <label htmlFor="objectiveName" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                                Nombre Objetivo
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                    type="text"
                                    name="objectiveName"
                                    id="objectiveName"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                            <label htmlFor="mount" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                                Monto Meta
                            </label>
                            <div className="sm:col-span-2 sm:mt-0 flex items-center gap-2">
                                <div className="w-full relative rounded-md">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <span className="text-gray-500 sm:text-sm">$</span>
                                    </div>
                                    <input
                                        type="text"
                                        name="price"
                                        id="price"
                                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                                        placeholder="0"
                                    />
                                    <div className="absolute inset-y-0 right-0 flex items-center">
                                        <label htmlFor="currency" className="sr-only">
                                            Currency
                                        </label>
                                        <select
                                            id="currency"
                                            name="currency"
                                            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-2 text-gray-500 placeholder:text-gray-400 focus-visible:outline-none sm:text-sm"
                                        >
                                            <option>Pesos</option>
                                            <option>UF</option>
                                            <option>Dolares</option>
                                        </select>
                                    </div>
                                </div>
                                <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="objectiveMountTooltip" data-tooltip-content="Descripción del Input" />
                                <Tooltip id="objectiveMountTooltip" />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                            <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                                Fecha
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                                <input
                                    type="text"
                                    name="date"
                                    id="date"
                                    autoComplete="given-name"
                                    placeholder="DD/MM/AAAA"
                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                                />
                                <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="objectiveDateTooltip" data-tooltip-content="Descripción del Input" />
                                <Tooltip id="objectiveDateTooltip" />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                            <label htmlFor="protectionType" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                                Tipo de Protección
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                                <select
                                    id="protectionType"
                                    name="buyingType"
                                    autoComplete="protectionType"
                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                                >
                                    <option>Deducible Seguro</option>
                                    <option>Full cobertura Seguro</option>
                                    <option>Salud y Bienestar</option>
                                    <option>Educación</option>
                                    <option>Vivienda</option>
                                    <option>Ingresos y Sustento</option>
                                    <option>Otras Necesidades Familiares</option>
                                </select>
                                <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="objectiveprotectionTypeTooltip" data-tooltip-content="Descripción del Input" />
                                <Tooltip id="objectiveprotectionTypeTooltip" />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                            <label htmlFor="objectiveDestiny" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                                Destino
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                                <input
                                    type="text"
                                    name="objectiveDestiny"
                                    id="objectiveDestiny"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                                />
                                <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="objectiveDestinyTooltip" data-tooltip-content="Descripción del Input" />
                                <Tooltip id="objectiveDestinyTooltip" />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                            <label htmlFor="ensure" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                                ¿Tienes Seguro?
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                                <select
                                    id="buyingType"
                                    name="ensure"
                                    autoComplete="ensure"
                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                                >
                                    <option>Seguro de vida</option>
                                    <option>Seguro de salud</option>
                                    <option>Seguro de hogar</option>
                                    <option>Seguro de responsabilidad civil</option>
                                    <option>Seguro educativo</option>
                                    <option>Seguro de gastos médicos mayores</option>
                                    <option>Seguro de invalidez</option>
                                    <option>Seguro de pérdida de ingresos</option>
                                    <option>Seguro de cuidado a largo plazo</option>
                                    <option>Seguro de renta</option>
                                    <option>Otros tipos de protección familiar</option>
                                    <option>No tengo seguro</option>

                                </select>
                                <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="objectiveEnsureTooltip" data-tooltip-content="Descripción del Input" />
                                <Tooltip id="objectiveEnsureTooltip" />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                            <label htmlFor="Desciption" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                                Descripción
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                                <textarea
                                    rows={4}
                                    name="Desciption"
                                    id="description"
                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                                    defaultValue={'Sugerencia de descripción personalizada para el objetivo Jubilación, de forma de levantar información relevante. Por ejemplo que otros ahorros tiene como AFP, vivienda, etc'}
                                />
                                <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="objectiveDesciptionTooltip" data-tooltip-content="Descripción del Input" />
                                <Tooltip id="objectiveDesciptionTooltip" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    )
}