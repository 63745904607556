import { Link, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';
import {
    QuestionMarkCircleIcon
} from '@heroicons/react/24/solid';

export default function ManualSearch() {

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <main className="lg:pr-64">
            <div className="xl:pl-72">
                <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
                    <div className="py-16 sm:py-16 lg:py-16">
                        <div className='pr-48 sm:pr-0 lg:pr-48'>
                            <div className="text-left mt-2 relative left-[-15px]">
                                <div className="inline-flex space-x-6">
                                    <button
                                        onClick={handleGoBack}>
                                        <ChevronLeftIcon className='text-blue-500 h-12 w-12' />
                                    </button>
                                </div>
                            </div>
                            <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                Búsqueda manual
                            </h1>
                            <div className='rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 h-1 w-24 mt-5'></div>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Puede utilizar los filtros para elegir un fondo de acuerdo a tus preferencias.
                            </p>
                        </div>

                        <form>
                            <div className="space-y-12 sm:space-y-16">
                                <div>
                                    <div className="mt-10 space-y-8 border-gray-900/10 pb-12 sm:space-y-0 sm:border-t sm:pb-0">

                                        <div className="sm:grid sm:grid-cols-4 sm:items-center sm:gap-4 sm:py-6">
                                            <label htmlFor="riskLevel" className="block text-sm font-medium text-gray-900 sm:py-1.5">
                                                Nivel de riesgo
                                            </label>
                                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                                                <select
                                                    id="riskLevel"
                                                    name="riskLevel"
                                                    autoComplete="riskLevel"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 ring-1 ring-gray-300 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none  sm:text-sm sm:"
                                                >
                                                    <option>Bajo</option>
                                                    <option>Moderado</option>
                                                    <option>Alto</option>
                                                </select>
                                                <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="riskLevelTooltip" data-tooltip-content="Descripción del Input" />
                                                <Tooltip id="riskLevelTooltip" />
                                            </div>
                                        </div>

                                        <div className="sm:grid sm:grid-cols-4 sm:items-center sm:gap-4 sm:py-6">
                                            <label htmlFor="fundType" className="block text-sm font-medium  text-gray-900 sm:py-1.5">
                                                Tipo de fondo
                                            </label>
                                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                                                <select
                                                    id="fundType"
                                                    name="fundType"
                                                    autoComplete="fundType"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 ring-1 ring-gray-300 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none  sm:text-sm sm:"
                                                >
                                                    <option>Fondos AFP</option>
                                                    <option>Vehículos</option>
                                                    <option>Propiedades</option>
                                                    <option>Acciones</option>
                                                    <option>Fondos mutuos</option>
                                                    <option>Inversiones exterior</option>
                                                    <option>Dólares</option>
                                                    <option>Otros</option>
                                                </select>
                                                <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="fundTypeTooltip" data-tooltip-content="Descripción del Input" />
                                                <Tooltip id="fundTypeTooltip" />
                                            </div>
                                        </div>

                                        <div className="sm:grid sm:grid-cols-4 sm:items-center sm:gap-4 sm:py-6">
                                            <label htmlFor="mount" className="block text-sm font-medium  text-gray-900 sm:py-1.5">
                                                Moneda
                                            </label>
                                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                                                <select
                                                    id="money"
                                                    name="money"
                                                    autoComplete="money"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 ring-1 ring-gray-300 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none  sm:text-sm sm:"
                                                >
                                                    <option>Pesos</option>
                                                    <option>UF</option>
                                                    <option>Dólares</option>
                                                </select>
                                                <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="moneyTooltip" data-tooltip-content="Descripción del Input" />
                                                <Tooltip id="moneyTooltip" />
                                            </div>
                                        </div>

                                        <div className="sm:grid sm:grid-cols-4 sm:items-center sm:gap-4 sm:py-6">
                                            <label htmlFor="benefit" className="block text-sm font-medium  text-gray-900 sm:py-1.5">
                                                Beneficio Tributario
                                            </label>
                                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                                                <select
                                                    id="benefit"
                                                    name="benefit"
                                                    autoComplete="benefit"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 ring-1 ring-gray-300 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none  sm:text-sm sm:"
                                                >
                                                    <option>Beneficio 1</option>
                                                    <option>Beneficio 2</option>
                                                    <option>Beneficio 3</option>
                                                    <option>Beneficio 4</option>
                                                </select>
                                                <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="benefitTooltip" data-tooltip-content="Descripción del Input" />
                                                <Tooltip id="benefitTooltip" />
                                            </div>
                                        </div>

                                        <div className="sm:grid sm:grid-cols-4 sm:items-center sm:gap-4 sm:py-6">
                                            <label htmlFor="investmentMount" className="block text-sm font-medium  text-gray-900 sm:py-1.5">
                                                Monto a invertir
                                            </label>
                                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                                                <input
                                                    type="text"
                                                    name="investmentMount"
                                                    id="investmentMount"
                                                    autoComplete="investmentMount"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:"
                                                />
                                                <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="investmentMountTooltip" data-tooltip-content="Descripción del Input" />
                                                <Tooltip id="investmentMountTooltip" />
                                            </div>
                                        </div>

                                        <div className="sm:grid sm:grid-cols-4 sm:items-center sm:gap-4 sm:py-6">
                                            <label htmlFor="investmentType" className="block text-sm font-medium  text-gray-900 sm:py-1.5">
                                                inversión en Chile o extranjero
                                            </label>
                                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                                                <select
                                                    id="investmentType"
                                                    name="investmentType"
                                                    autoComplete="investmentType"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 ring-1 ring-gray-300 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none  sm:text-sm sm:"
                                                >
                                                    <option>Chile</option>
                                                    <option>Extranjero</option>
                                                </select>
                                                <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="investmentTypeTooltip" data-tooltip-content="Descripción del Input" />
                                                <Tooltip id="investmentTypeTooltip" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="pt-10 border-t border-gray-900/10 flex items-center justify-center gap-x-6">
                                <Link
                                    to={'/resultadoBusquedaManual'}
                                    className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-8 py-4 text-lg font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                >
                                    Buscar
                                </Link>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </main>

    )
}