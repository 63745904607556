// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
    { name: 'Inicio', href: '/landing' },
    { name: 'Nosotros', href: '#' },
    { name: 'Faq y Ayuda', href: '#' },
]

export default function Header({ setSidebarOpen, handleLogout, isAuthenticated }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    return (
        <header className={`bg-white ${isAuthenticated ? 'lg:pr-64 lg:pl-72' : ''}`}>
            <nav className="mx-auto flex items-center justify-between gap-x-6 p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <a href="#" className="-m-1.5 p-1.5">
                        <span className="sr-only">Blue Bridge</span>
                        <img
                            className="h-8 w-auto"
                            src={process.env.PUBLIC_URL + 'logo-bb.svg'}
                            alt="Blue Bridge"
                        />
                    </a>
                </div>
                <div className="flex lg:flex-1 lg:hidden">
                    <button onClick={() => setSidebarOpen(true)} className="-m-1.5 p-1.5">
                        <span className="sr-only">Blue Bridge</span>
                        <img
                            className="h-8 w-auto"
                            src={process.env.PUBLIC_URL + 'logo-bb.svg'}
                            alt="Blue Bridge"
                        />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item) => (
                        <Link key={item.name} to={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                            {item.name}
                        </Link>
                    ))}
                </div>
                <div className='flex flex-1 items-center justify-end gap-x-6'>
                    {isAuthenticated ? (
                        <a
                            href="#"
                            onClick={() => handleLogout(false)}
                            className="lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900"
                        >
                            Cerrar Sesión
                        </a>
                    ) : (
                        <>
                            <Link
                                to="/registro"
                                className="lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900"
                                >
                                Registrarse
                            </Link>
                            <Link
                                to="/login"
                                className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-5 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                            >
                                Ingresar
                            </Link>
                        </>
                    )}
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center gap-x-6">
                        <button onClick={() => setSidebarOpen(true)} className="-m-1.5 p-1.5">
                            <span className="sr-only">Blue Bridge</span>
                            <img
                                className="h-8 w-auto"
                                src={process.env.PUBLIC_URL + 'logo-bb.svg'}
                                alt="Blue Bridge"
                            />
                        </button>
                        <a
                            href="#"
                            className="ml-auto rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Registrarse
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <div className="py-6">
                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Ingresar
                                </a>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
};
