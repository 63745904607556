import { ScaleIcon, RocketLaunchIcon, ShieldCheckIcon } from '@heroicons/react/24/outline'

const solutions = [
    {
        name: 'Baja',
        description: 'Tu principal preocupación es preservar el capital. Las rentabilidades pueden ser bajas, pero se compensan con una alta liquidez y una reducción del riesgo de pérdidas.',
        href: '#',
        icon: ShieldCheckIcon,
    },
    {
        name: 'Media',
        description: 'Estás dispuesto a aceptar cierta pérdida de capital en el corto plazo buscando un posible rendimiento total más alto en el mediano plazo. La diversificación y la asignación de activos ayudan a reducir este nivel de riesgo.',
        href: '#',
        icon: ScaleIcon,
    },
    {
        name: 'Alta',
        description: "Estás dispuesto a aceptar el riesgo de una posible pérdida de capital en el corto e incluso en el mediano plazo porque crees que la rentabilidad de las inversiones pueden estar por encima del promedio en el futuro. Los resultados pueden variar ampliamente de un año a otro en la búsqueda de metas a largo plazo.",
        href: '#',
        icon: RocketLaunchIcon,
    },
]

export default function RiskToleranceButton() {

    return (
        <>
            <div className="flex items-center">
                <div className="mt-5">
                    <h3 className="text-xl text-[#050624] font-bold leading-6">Tu tolerancia al riesgo para este objetivo es:</h3>
                </div>
            </div>
            <div className="mx-auto grid grid-cols-1 gap-2 pt-5 sm:grid-cols-1 sm:gap-x-6 sm:gap-y-0 sm:pt-5 lg:grid-cols-3 lg:gap-4 xl:gap-8">
                {solutions.map((item) => (
                    <div
                        key={item.name}
                        className="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm text-gray-600 leading-6 focus-within:bg-blue-500 hover:bg-blue-500 focus-within:text-white hover:text-white sm:flex-col sm:p-6 hover:drop-shadow-2xl ease-in-out duration-200"
                    >
                        <div className="flex h-20 w-20 flex-none items-center justify-center rounded-lg bg-gray-50 group-focus-within:bg-gray-50/5 group-hover:bg-gray-50/5 ease-in-out duration-200">
                            <item.icon className="h-12 w-12 text-gray-600 group-focus-within:text-white group-hover:text-white" aria-hidden="true" />
                        </div>
                        <div>
                            <button className="font-semibold text-xl">
                                {item.name}
                                <span className="absolute inset-0" />
                            </button>
                            <p className="mt-1">{item.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}