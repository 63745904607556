import { useState } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es'; // Importa el idioma español

export default function SurveyDatepicker({ settings }) {
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    return (
        <>
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                <h2 className="my-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                ¿Cual es tu fecha de nacimiento?
                </h2>
            </div>

            <div className="mb-10 sm:mx-auto sm:w-full sm:max-w-lg flex items-center justify-center">
                    <DatePicker
                    className='my-10 text-center text-lg font-normal cursor-pointer leading-6 tracking-tight text-gray-600 focus:ring-0 focus-visible:outline-none'
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="dd   /   MMMM   /   yyyy" // Formato de fecha
                        placeholderText="Selecciona una fecha" // Texto de marcador de posición
                        locale={es} // Establece el idioma español
                        showYearDropdown // Habilita el selector de años
                    />

            </div>
        </>
    );
}
