import { Link } from 'react-router-dom';
import { ScaleIcon, RocketLaunchIcon, ShieldCheckIcon } from '@heroicons/react/24/outline'

const solutions = [
    /*{
        name: 'Baja',
        description: 'Tu principal preocupación es preservar el capital. Las rentabilidades pueden ser bajas, pero se compensan con una alta liquidez y una reducción del riesgo de pérdidas.',
        href: '#',
        icon: ShieldCheckIcon,
    },*/
    {
        name: 'Moderado',
        description: 'Estás dispuesto a aceptar cierta pérdida de capital en el corto plazo buscando un posible rendimiento total más alto en el mediano plazo. La diversificación y la asignación de activos ayudan a reducir este nivel de riesgo.',
        href: '#',
        icon: ScaleIcon,
    },
    /*{
        name: 'Alta',
        description: "Estás dispuesto a aceptar el riesgo de una posible pérdida de capital en el corto e incluso en el mediano plazo porque crees que la rentabilidad de las inversiones pueden estar por encima del promedio en el futuro. Los resultados pueden variar ampliamente de un año a otro en la búsqueda de metas a largo plazo.",
        href: '#',
        icon: RocketLaunchIcon,
    },*/
]

export default function SurveyResult() {

    return (
        <>
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                <h2 className="my-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                    Tu perfil es
                </h2>
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-lg mb-10">
                <div className="mx-auto">
                    {solutions.map((item) => (
                        <div
                            key={item.name}
                            className="relative flex gap-6 rounded-lg p-3 text-sm text-gray-600 leading-6 bg-white border border-2 border-blue-500 sm:flex-col sm:items-center sm:p-6 ease-in-out duration-200"
                        >
                            <div className="flex h-20 w-20 flex-none items-center justify-center rounded-lg bg-blue-500 ease-in-out duration-200">
                                <item.icon className="h-12 w-12 text-white" aria-hidden="true" />
                            </div>
                            <div className='text-center'>
                                <button className="font-semibold text-xl">
                                    {item.name}
                                    <span className="absolute inset-0" />
                                </button>
                                <p className="mt-1">{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <Link
                    to="/encuestaDeInversion"
                    className="w-full block mt-10 text-center rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-5 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                    Ir a mi cuenta
                </Link>
                <p className="mt-10 text-center text-sm text-gray-500">
                    Si crees que el resultado no te identifica puedes {' '}
                    <Link
                        to="/registro"
                        className="font-semibold leading-6 text-blue-500 hover:text-blue-500"
                    >
                        completar tu perfil de nuevo
                    </Link>
                </p>
            </div>
        </>
    );
}
