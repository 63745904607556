import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TitleStats({ titleStats }) {
  return (
    <div>
      <dl className="grid grid-cols-1 md:grid-cols-1">
        {titleStats.map((item) => (
          <div key={item.name} className="pb-8">
            <dt className="text-xl font-bold text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-end justify-between md:block lg:flex">
              <div className="text-2xl mr-2 font-semibold text-blue-600">
                <div className='-mb-2'>{item.stat}</div>
                <span className="text-sm font-medium text-gray-500">de {item.previousStat}</span>
              </div>

              <div
                className={classNames(
                  item.changeType === 'increase' ? 'bg-lime-100 text-lime-800' : 'bg-red-100 text-red-800',
                  'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowUpIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-lime-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowDownIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )}

                <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                {item.change}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
