import React from 'react';
import { Link } from 'react-router-dom';

export default function ProfileSurvey() {

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                    <h2 className="mt-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                        Te haremos unas preguntas para conocer tu perfil
                    </h2>
                    <h3 className='mt-10 text-center text-xl font-normal leading-6 tracking-tight text-blue-400'>
                        Es fácil y te tomará menos de un minuto</h3>
                </div>

                <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                    <p className="my-10 text-center text-sm text-gray-500">
                        Nos permite encontrar las mejores opciones y para que las administradoras cumplan con la normativa de la CMF y la UAF.
                    </p>

                    <Link
                        to="/encuesta"
                        className="w-full block text-center rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-5 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                    >
                        Siguiente
                    </Link>

                </div>
            </div>
        </>
    );
}
