function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const handleDownload = () => {
    // URL del archivo PDF local
    const pdfUrl = `${process.env.PUBLIC_URL}/dummy.pdf`;

    // Crea un enlace temporal
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'sample.pdf'; // Nombre de archivo para la descarga
    document.body.appendChild(link);

    // Simula un clic en el enlace para iniciar la descarga
    link.click();

    // Limpia el enlace temporal
    document.body.removeChild(link);
  };

export default function InvestmentSurveyStep6({ }) {

    return (
        <>
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                <h2 className="my-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                    Términos y condiciones
                </h2>
                <h3 className='mb-10 text-center text-xl font-normal leading-6 tracking-tight text-gray-600'>
                    Para invertir en fondos mutuos, necesitas firmar contratos de administración de fondos con cada administradora. </h3>
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-lg mb-10">
                <fieldset className="border-b border-t border-gray-200">
                    <div className="divide-y divide-gray-200">
                        <div className="relative flex items-start pb-4 pt-3.5">
                            <div className="min-w-0 flex-1 text-sm leading-6">
                                <label htmlFor="comments" className="font-medium text-gray-900">
                                    Bci
                                </label>
                                <button onClick={handleDownload} id="comments-description" className="block text-blue-500">
                                    Ver Contrato
                                </button>
                            </div>
                            <div className="ml-3 flex h-6 items-center">
                                <input
                                    id="comments"
                                    aria-describedby="comments-description"
                                    name="comments"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                                />
                            </div>
                        </div>
                        <div className="relative flex items-start pb-4 pt-3.5">
                            <div className="min-w-0 flex-1 text-sm leading-6">
                                <label htmlFor="candidates" className="font-medium text-gray-900">
                                    Security
                                </label>
                                <button onClick={handleDownload} id="comments-description" className="block text-blue-500">
                                    Ver Contrato
                                </button>
                            </div>
                            <div className="ml-3 flex h-6 items-center">
                                <input
                                    id="candidates"
                                    aria-describedby="candidates-description"
                                    name="candidates"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                                />
                            </div>
                        </div>
                        <div className="relative flex items-start pb-4 pt-3.5">
                            <div className="min-w-0 flex-1 text-sm leading-6">
                                <label htmlFor="offers" className="font-medium text-gray-900">
                                    BTG Pactual
                                </label>
                                <button onClick={handleDownload} id="comments-description" className="block text-blue-500">
                                    Ver Contrato
                                </button>
                            </div>
                            <div className="ml-3 flex h-6 items-center">
                                <input
                                    id="offers"
                                    aria-describedby="offers-description"
                                    name="offers"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                                />
                            </div>
                        </div>
                        <div className="relative flex items-start pb-4 pt-3.5">
                            <div className="min-w-0 flex-1 text-sm leading-6">
                                <label htmlFor="offers" className="font-medium text-gray-900">
                                    Mandato BlueBridge
                                </label>
                                <button onClick={handleDownload} id="comments-description" className="block text-blue-500">
                                    Ver Contrato
                                </button>
                            </div>
                            <div className="ml-3 flex h-6 items-center">
                                <input
                                    id="offers"
                                    aria-describedby="offers-description"
                                    name="offers"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                                />
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </>
    );
}
