import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import {
    PlayCircleIcon
} from '@heroicons/react/24/outline';
import {
    QuestionMarkCircleIcon
} from '@heroicons/react/24/solid';

const tabs = [
    { name: 'Objetivos', href: '#', current: true },
    { name: 'Detalle', href: '#', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function InvestmentObjectives() {

    return (
        <div className="border-b border-gray-200 py-5">
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="flex items-center">
                    <div className="mb-4">
                        <h3 className="text-xl text-[#050624] font-bold leading-6">Objetivos</h3>
                    </div>
                </div>
                <div className="flex flex-shrink-0">
                    <a
                        href='#'
                        className="relative ml-3 inline-flex items-center text-sm font-semibold text-gray-900"
                    >
                        <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="objectiveHelpTooltip" data-tooltip-content="Ayuda" />
                        <Tooltip id="objectiveHelpTooltip" place='left'/>
                    </a>
                </div>
            </div>

            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>

                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                        defaultValue={tabs.find((tab) => tab.current).name}
                    >
                        {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <a
                                    key={tab.name}
                                    href={tab.href}
                                    className={classNames(
                                        tab.current
                                            ? 'border-blue-500 text-blue-500'
                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                        'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                                    )}
                                    aria-current={tab.current ? 'page' : undefined}
                                >
                                    {tab.name}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>

            <div className="pt-16 pb-8">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Crea tus objetivos
                    </h2>
                    <p className="mx-auto mt-2 max-w-xl text-lg leading-8 text-gray-600">
                        y comienza a invertir
                    </p>
                    <PlayCircleIcon className="mx-auto mt-5 h-40 w-40 text-gray-400" />
                </div>
            </div>
            <div className="flex justify-end mb-5">
                <Link
                    to="/objetivos"
                    className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-5 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                    Agregar Objetivo
                </Link>

            </div>
        </div>
    )
}