export default function VideoPlayer({ video }) {

    return (
        <div>
        <video className="w-full aspect-video" controls>
          <source src={video.src} type="video/mp4" />
          Tu navegador no soporta el tag de video.
        </video>
      </div>
    )
};