export default function ObjectiveHeader({ objective }) {

    return (
        <>
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap border-b border-gray-200 py-5">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <img
                            className="h-12 w-12 rounded-full"
                            src={objective.imageUrl}
                            alt=""
                        />
                    </div>
                    <div className="ml-4">
                        <h3 className="text-xl text-[#050624] font-bold leading-6">{objective.name}</h3>
                        <div className='flex items-center'>
                            <h3 className='text-md text-[#050624]'>Toleracia al riesgo:</h3>
                            <div className={`ml-2 rounded-md
                            ${objective.riskTolerance === 'Alta' && 'bg-rose-500'}
                            ${objective.riskTolerance === 'Media' && 'bg-blue-500'}
                            ${objective.riskTolerance === 'Baja' && 'bg-lime-500'}
                            text-white py-1 px-4`}>
                                {objective.riskTolerance}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}