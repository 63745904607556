function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function InvestmentSurveyStep1({ }) {

    return (
        <>
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                <h2 className="my-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                    Necesitamos unos datos
                </h2>
                <h3 className='mb-10 text-center text-xl font-normal leading-6 tracking-tight text-gray-600'>
                    Tu información es necesaria para firmar los contrato de administración de fondos exigidos por la CMF.</h3>
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-lg mb-10">
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Nombre
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="name"
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="fatherLastName" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Apellido Paterno
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="fatherLastName"
                            id="fatherLastName"
                            autoComplete="fatherLastName"
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="motherLastName" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Apellido Materno
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="motherLastName"
                            id="motherLastName"
                            autoComplete="motherLastName"
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="sm:gap-4 sm:pb-2">
                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Pais de Nacimiento
                    </label>
                    <div className="mt-1 gap-2">
                        <select
                            id="country"
                            name="country"
                            autoComplete="country"
                            className="block w-full h-9 rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        >
                            <option value="CHL">Chile</option>
                            <option value="ARG">Argentina</option>
                            <option value="BOL">Bolivia</option>
                            <option value="BRA">Brasil</option>
                            <option value="COL">Colombia</option>
                            <option value="CRI">Costa Rica</option>
                            <option value="CUB">Cuba</option>
                            <option value="DOM">República Dominicana</option>
                            <option value="ECU">Ecuador</option>
                            <option value="SLV">El Salvador</option>
                            <option value="GTM">Guatemala</option>
                            <option value="HND">Honduras</option>
                            <option value="MEX">México</option>
                            <option value="NIC">Nicaragua</option>
                            <option value="PAN">Panamá</option>
                            <option value="PRY">Paraguay</option>
                            <option value="PER">Perú</option>
                            <option value="PRI">Puerto Rico</option>
                            <option value="URY">Uruguay</option>
                            <option value="VEN">Venezuela</option>
                            <option value="USA">Estados Unidos</option>
                            <option value="CAN">Canadá</option>

                        </select>
                    </div>
                </div>
                <div className="sm:gap-4 sm:pb-2">
                    <label htmlFor="nationality" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Nacionalidad
                    </label>
                    <div className="mt-1 gap-2">
                        <select
                            id="nationality"
                            name="nationality"
                            autoComplete="nationality"
                            className="block w-full h-9 rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        >
                            <option value="CHL">Chileno/a</option>
                            <option value="ARG">Argentino/a</option>
                            <option value="BOL">Boliviano/a</option>
                            <option value="BRA">Brasileño/a</option>
                            <option value="COL">Colombiano/a</option>
                            <option value="CRI">Costarricense</option>
                            <option value="CUB">Cubano/a</option>
                            <option value="DOM">Dominicano/a</option>
                            <option value="ECU">Ecuatoriano/a</option>
                            <option value="SLV">Salvadoreño/a</option>
                            <option value="GTM">Guatemalteco/a</option>
                            <option value="HND">Hondureño/a</option>
                            <option value="MEX">Mexicano/a</option>
                            <option value="NIC">Nicaragüense</option>
                            <option value="PAN">Panameño/a</option>
                            <option value="PRY">Paraguayo/a</option>
                            <option value="PER">Peruano/a</option>
                            <option value="PRI">Puertorriqueño/a</option>
                            <option value="URY">Uruguayo/a</option>
                            <option value="VEN">Venezolano/a</option>
                            <option value="USA">Estadounidense</option>
                            <option value="CAN">Canadiense</option>

                        </select>
                    </div>
                </div>

            </div>
        </>
    );
}
