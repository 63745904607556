import React from 'react';
import VideoPlayer from './VideoPlayer';

const video = [
    {
        name: 'Video IA',
        description: 'Video con IA',
        src: require('../../assets/test.mp4'),
    }
]

export default function IAChatColumn({ contact }) {
    return (
        <aside className="bg-[#FAFBFC] fixed inset-y-0 right-0 hidden w-64 overflow-y-auto px-6 py-7 sm:px-7 lg:px-7 xl:block">
            <h3 className='font-bold mb-1 mt-16'>Bard GPT</h3>
            <form className='bg-[#D3E1F5] p-4 rounded-lg' action="#">
                <label htmlFor="message" className="sr-only">
                    Mensaje
                </label>
                <div>
                    <textarea
                        rows={5}
                        name="message"
                        id="message"
                        className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 placeholder:text-gray-400 focus:border-0 focus-visible:outline-0 sm:text-sm sm:leading-6"
                        placeholder="Escribe tu mensaje"
                        defaultValue={''}
                    />
                </div>

                <div className="mt-5 block w-full border-0 bg-white rounded-md border-0 py-2 px-1.5 text-gray-900">
                    <div className="text-sm leading-5 text-gray-400">
                        Respuesta
                    </div>
                </div>

                <div className="mt-5">
                    <VideoPlayer video={video[0]}/>
                </div>

                <div className="mt-5 flex justify-start">
                    <button
                        type="submit"
                        className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 w-full px-5 py-2 text-sm font-semibold text-white hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                    >
                        Enviar
                    </button>
                </div>
            </form>
            <div className="w-full mt-10">
            <h3 className='font-bold mb-2'>Contacto</h3>
                {contact.map((contact) => (
                    <div
                        key={contact.email}
                        className="relative flex items-center space-x-3"
                    >
                        <div className="flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src={contact.imageUrl} alt="" />
                        </div>
                        <div className="min-w-0 flex-1">
                            <a href="#" className="focus:outline-none">
                                <span className="absolute inset-0" aria-hidden="true" />
                                <p className="text-sm font-medium text-gray-900">{contact.name}</p>
                                <p className="text-sm text-gray-500">{contact.phoneNumber}</p>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </aside>
    );
};
