export default function Articles({ featuredArticle, articles }) {

    return (
        <div className="border-b border-gray-200 py-10 sm:py-10">
            <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="flex items-center">
                    <div className="mb-16">
                        <h3 className="text-xl text-[#050624] font-bold leading-6">Artículos</h3>
                    </div>
                </div>
            </div>

            <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-12 sm:gap-y-16 lg:grid-cols-2">
                <article className="mx-auto w-full max-w-2xl lg:mx-0 lg:max-w-lg">
                    <time dateTime={featuredArticle.datetime} className="block text-sm leading-6 text-gray-600">
                        {featuredArticle.date}
                    </time>
                    <h2 id="featured-post" className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        {featuredArticle.title}
                    </h2>
                    <img
                        src={featuredArticle.imageUrl}
                        alt=""
                        className="mt-5 aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                    />
                    <p className="mt-4 text-lg leading-8 text-gray-600">{featuredArticle.description}</p>
                    <div className="mt-4 flex flex-col justify-between gap-6 sm:mt-8 sm:flex-row-reverse sm:gap-8 lg:mt-4 lg:flex-col">
                        <div className="flex">
                            <a
                                href={featuredArticle.href}
                                className="text-sm font-semibold leading-6 text-blue-600"
                                aria-describedby="featured-post"
                            >
                                Continuar leyendo <span aria-hidden="true">&rarr;</span>
                            </a>
                        </div>
                        <div className="flex lg:border-t lg:border-gray-900/10 lg:pt-8">
                            <a
                                href={featuredArticle.author.href}
                                className="flex gap-x-2.5 text-sm font-semibold leading-6 text-gray-900"
                            >
                                <img src={featuredArticle.author.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full bg-gray-50" />
                                {featuredArticle.author.name}
                            </a>
                        </div>
                    </div>
                </article>
                <div className="mx-auto w-full max-w-2xl border-t border-gray-900/10 pt-12 sm:pt-16 lg:mx-0 lg:max-w-none lg:border-t-0 lg:pt-0">
                    <div className="-my-12 divide-y divide-gray-900/10">
                        {articles.map((post) => (
                            <article key={post.id} className="py-12">
                                <div className="group relative max-w-xl">
                                    <time dateTime={post.datetime} className="block text-sm leading-6 text-gray-600">
                                        {post.date}
                                    </time>
                                    <h2 className="mt-2 text-lg font-semibold text-gray-900 group-hover:text-gray-600">
                                        <a href={post.href}>
                                            <span className="absolute inset-0" />
                                            {post.title}
                                        </a>
                                    </h2>
                                    <p className="mt-4 text-sm leading-6 text-gray-600">{post.description}</p>
                                </div>
                                <div className="mt-4 flex">
                                    <a
                                        href={post.author.href}
                                        className="relative flex gap-x-2.5 text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        <img src={post.author.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full bg-gray-50" />
                                        {post.author.name}
                                    </a>
                                </div>
                            </article>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};