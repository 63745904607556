import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from '../auth/Login';
import Register from '../auth/Register';
import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';
import DesktopSidebar from '../components/common/DesktopSidebar';
import IAChatColumn from '../components/common/IAChatColumn';
import Home from '../pages/Home';
import Objectives from '../pages/Objectives';
import ObjectiveLayout from '../layouts/ObjectiveLayout';
import SearchSelector from '../pages/SearchSelector';
import ManualSearch from '../pages/ManualSearch';
import SuggestedSearch from '../pages/SuggestedSearch';
import ManualSearchResult from '../pages/ManualSearchResult';
import ScrollToTopOnMount from '../components/common/ScrollToTopOnMount';
import MailVerification from '../auth/MailVerification';
import ProfileSurvey from '../auth/ProfileSurvey';
import Survey from '../auth/Survey';
import SurveyResult from '../auth/SurveyResult';
import InvestmentProfileSurvey from '../auth/InvestmentProfileSurvey';
import InvestmentSurvey from '../auth/InvestmentSurvey';
import InvestmentSurveyResult from '../auth/InvestmentSurveyResult';

import {
    UserIcon,
    ChartPieIcon,
    Cog8ToothIcon,
    HomeIcon,
    CreditCardIcon,
    DocumentDuplicateIcon,
    QuestionMarkCircleIcon
} from '@heroicons/react/24/outline';
import PassRecovery from '../auth/PassRecovery';
import PassRecoveryResult from '../auth/PassRecoveryResult';
import Landing from '../pages/Landing';

const user = [
    {
        name: 'Javier Lira',
        riskProfile: 'Moderado',
        email: 'javier.lira@gmail.com',
        phoneNumber: '+56 9 36767896',
        imageUrl:
            'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    }
];

const contact = [
    {
        name: 'Alejandro Osses',
        email: 'alejandro@cristalino.cl',
        phoneNumber: '+56 9 36767896',
        imageUrl:
            'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    }
];

const navigation = [
    { name: 'Home', href: '/', icon: HomeIcon, current: false },
    { name: 'Invertir', href: 'invertir', icon: ChartPieIcon, current: false },
    { name: 'Movimientos', href: 'movimientos', icon: CreditCardIcon, current: false },
    { name: 'Documentos', href: 'documentos', icon: DocumentDuplicateIcon, current: false },
    { name: 'Perfil', href: 'perfil', icon: UserIcon, current: false },
    { name: 'Configuración', href: 'configuracion', icon: Cog8ToothIcon, current: false },
    { name: 'Ayuda', href: 'ayuda', icon: QuestionMarkCircleIcon, current: false },
];

// Definir el arreglo JSON con usuarios de ejemplo
const users = [
    { email: 'user@gmail.cl', password: 'pass' },
    { email: 'user2', password: 'pass' },
];

export default function Layout() {
    const [currentNavigation, setCurrentNavigation] = useState('Home');
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const isAuthenticatedSession = sessionStorage.getItem('isAuthenticated');
        if (isAuthenticatedSession === 'true') {
            setIsAuthenticated(true);
            console.log(isAuthenticated);
            console.log(isAuthenticatedSession);
            navigate('/');
        }
    }, []);

    const handleLogin = (email, password) => {
        // Verificar las credenciales del usuario
        const user = users.find(user => user.email === email && user.password === password);

        if (user) {
            setIsAuthenticated(true);
            sessionStorage.setItem('isAuthenticated', 'true');
            navigate('/');
            console.log(user);
        } else {
            // Si las credenciales no son válidas, mostrar un mensaje de error
            console.log(user);
            alert('Credenciales incorrectas. Por favor, inténtalo de nuevo.');
        }
    };

    const handleLogout = () => {
        sessionStorage.removeItem('isAuthenticated');
        setIsAuthenticated(false);
    };

    return (
        <>
            <ScrollToTopOnMount />
            {isAuthenticated && (
                <>
                    <Sidebar navigation={navigation} currentNavigation={currentNavigation} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    <DesktopSidebar navigation={navigation} />
                </>
            )}
            <Header setSidebarOpen={setSidebarOpen} handleLogout={handleLogout} isAuthenticated={isAuthenticated} />

            <Routes>
                <Route
                    path="/"
                    element={
                        isAuthenticated ? <Home user={user[0]} /> : <Navigate to="/login" />
                    }
                    onEnter={() => setCurrentNavigation('Home')}
                />

                <Route path="/landing" element={<Landing/>} />
                <Route path="/login" element={<Login onLogin={handleLogin} />} />
                <Route path="/registro" element={<Register/>} />
                <Route path="/recuperarContraseña" element={<PassRecovery/>} />
                <Route path="/recuperarContraseñaCorreoEnviado" element={<PassRecoveryResult/>} />
                <Route path="/verificaciónEmail" element={<MailVerification/>} />
                <Route path="/encuestaDePerfil" element={<ProfileSurvey/>} />
                <Route path="/encuestaDeInversion" element={<InvestmentProfileSurvey/>} />
                <Route path="/encuesta" element={<Survey/>} />
                <Route path="/encuestaI" element={<InvestmentSurvey/>} />
                <Route path="/resultadoEncuesta" element={<SurveyResult/>} />
                <Route path="/resultadoEncuestaI" element={<InvestmentSurveyResult setIsAuthenticated={setIsAuthenticated}/>} />
                <Route path="/objetivos" element={<Objectives />} />
                <Route
                    path="/definicionObjetivo/:selectedObjective"
                    element={<ObjectiveLayout />}
                />
                <Route path="/selectorBusqueda" element={<SearchSelector />} />
                <Route path="/busquedaManual" element={<ManualSearch />} />
                <Route path="/busquedaSugerida" element={<SuggestedSearch />} />
                <Route
                    path="/resultadoBusquedaManual"
                    element={<ManualSearchResult />}
                />
            </Routes>
            {isAuthenticated && (
                <IAChatColumn contact={contact} />
            )}

        </>
    );
}
