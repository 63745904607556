import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SurveyRadio({settings}) {
    const [selected, setSelected] = useState(settings[0])

    return (
        <>
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                <h2 className="my-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                    {settings.stepTitle}
                </h2>
                <h3 className='mb-10 text-center text-xl font-normal leading-6 tracking-tight text-gray-600'>
                        {settings.stepSubtitle}</h3>
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-lg mb-10">
                <RadioGroup value={selected} onChange={setSelected}>
                    <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
                    <div className="-space-y-px rounded-md bg-white">
                        {settings.settings.map((setting, settingIdx) => (
                            <RadioGroup.Option
                                key={setting.name}
                                value={setting}
                                className={({ checked }) =>
                                    classNames(
                                        settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                        settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                        checked ? 'z-10 border-blue-200 bg-indigo-50' : 'border-gray-200',
                                        'relative flex cursor-pointer border p-4 focus:outline-none'
                                    )
                                }
                            >
                                {({ active, checked }) => (
                                    <>
                                        <span
                                            className={classNames(
                                                checked ? 'bg-blue-600 border-transparent' : 'bg-white border-gray-300',
                                                active ? 'ring-2 ring-offset-2 ring-blue-600' : '',
                                                'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                                            )}
                                            aria-hidden="true"
                                        >
                                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                                        </span>
                                        <span className="ml-3 flex flex-col">
                                            <RadioGroup.Label
                                                as="span"
                                                className={classNames(checked ? 'text-blue-900' : 'text-gray-900', 'block text-sm font-medium')}
                                            >
                                                {setting.name}
                                            </RadioGroup.Label>
                                            <RadioGroup.Description
                                                as="span"
                                                className={classNames(checked ? 'text-blue-700' : 'text-gray-500', 'block text-sm')}
                                            >
                                                {setting.description}
                                            </RadioGroup.Description>
                                        </span>
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>

            </div>
        </>
    );
}
