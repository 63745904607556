function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function InvestmentSurveyStep3({ }) {

    return (
        <>
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                <h2 className="my-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                    Datos de contacto
                </h2>
                <h3 className='mb-10 text-center text-xl font-normal leading-6 tracking-tight text-gray-600'>
                    Es importante que tu información sea la correcta para comunicarnos contigo.</h3>
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-lg mb-10">
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-gray-900">
                        Número de Teléfono (Celular)
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 flex items-center">
                            <label htmlFor="phone-number" className="sr-only">
                                Pais
                            </label>
                            <select
                                id="phone-number"
                                name="phone-number"
                                autoComplete="phone-number"
                                className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm"
                            >
                                <option value="56">CL</option>
                                <option value="1">US</option>
                                <option value="1">CA</option>
                                <option value="52">MX</option>
                                <option value="502">GT</option>
                                <option value="503">SV</option>
                                <option value="504">HN</option>
                                <option value="505">NI</option>
                                <option value="506">CR</option>
                                <option value="507">PA</option>
                                <option value="51">PE</option>
                                <option value="52">CO</option>
                                <option value="53">VE</option>
                                <option value="54">AR</option>
                                <option value="55">BR</option>
                                <option value="57">EC</option>
                                <option value="58">BO</option>
                                <option value="591">PY</option>
                                <option value="592">UY</option>
                                <option value="593">GY</option>
                                <option value="594">SR</option>
                                <option value="595">FK</option>
                            </select>
                        </div>
                        <input
                            type="text"
                            name="phone-number"
                            id="phone-number"
                            className="block w-full rounded-md border-0 py-1.5 pl-24 pr-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                            placeholder="9 6373 7893"
                        />
                    </div>
                </div>
                <div className="sm:gap-4 sm:pb-2">
                    <label htmlFor="HouseType" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Tipo de Domicilio
                    </label>
                    <div className="mt-1 gap-2">
                        <select
                            id="HouseType"
                            name="HouseType"
                            autoComplete="HouseType"
                            className="block w-full h-9 rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        >
                            <option value="Casa">Casa</option>
                            <option value="Departamento">Departamento</option>
                            <option value="Oficina">Oficina</option>
                            <option value="Apartamento">Apartamento</option>
                            <option value="Piso">Piso</option>
                            <option value="Local">Local</option>
                            <option value="Finca">Finca</option>
                            <option value="Habitación">Habitación</option>
                            <option value="Loft">Loft</option>
                            <option value="Estudio">Estudio</option>
                            <option value="Chalet">Chalet</option>
                            <option value="Rancho">Rancho</option>
                            <option value="Cabaña">Cabaña</option>
                            <option value="Bungalow">Bungalow</option>
                            <option value="Villa">Villa</option>
                            <option value="Quinta">Quinta</option>

                        </select>
                    </div>
                </div>
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Dirección
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="address"
                            id="address"
                            autoComplete="address"
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

            </div>
        </>
    );
}
