// Sidebar.js
import React from 'react';
import { Fragment } from 'react';
import { useState } from 'react';
import { Switch } from '@headlessui/react';
import { Dialog, Transition } from '@headlessui/react';
import {
    XMarkIcon,
} from '@heroicons/react/24/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Sidebar({ navigation, currentNavigation, sidebarOpen, setSidebarOpen }) {
    const [enabled, setEnabled] = useState(false);
    const handleToggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button type="button" className="-m-2.5 p-2.5" onClick={handleToggleSidebar}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <img
                                            className="h-8 w-auto"
                                            src={process.env.PUBLIC_URL + 'logo-bb.svg'}
                                            alt="Blue Bridge"
                                        />
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                            <li>
                                                <ul role="list" className="-mx-2 space-y-1">
                                                    {navigation.map((item, index) => (
                                                        index !== navigation.length - 1 && (
                                                            <li key={item.name}>
                                                                <a
                                                                    href={item.href}
                                                                    className={classNames(
                                                                        item.name === currentNavigation
                                                                            ? 'bg-[#F0F4FA] text-blue-500 before:block before:absolute before:h-4 before:w-2 before:mt-1 before:t-0 before:l-0 before:bg-blue-500 before:rounded-tr-full before:rounded-br-full before:left-0'
                                                                            : 'text-[#050624] hover:text-blue-500 hover:bg-[#F0F4FA]',
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold ease-in-out duration-200'
                                                                    )}
                                                                >
                                                                    <item.icon
                                                                        className={classNames(
                                                                            item.name === currentNavigation
                                                                                ? 'text-blue-500'
                                                                                : 'text-[#A4B4CB] group-hover:text-blue-500',
                                                                            'h-6 w-6 shrink-0 ease-in-out duration-200'
                                                                        )}
                                                                        aria-hidden="true"
                                                                    />
                                                                    {item.name}
                                                                </a>
                                                            </li>
                                                        )
                                                    ))}
                                                </ul>
                                            </li>
                                            <li className="mt-auto">
                                                <ul>
                                                    <li className="mb-3">
                                                        {navigation.map((item, index) => (
                                                            <React.Fragment key={item.name}>
                                                                {index === navigation.length - 1 && (
                                                                    <ul>
                                                                        <li>
                                                                            <a
                                                                                href={item.href}
                                                                                className={classNames(
                                                                                    item.current
                                                                                        ? 'bg-gray-50 text-blue-600'
                                                                                        : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold ease-in-out duration-200'
                                                                                )}
                                                                            >
                                                                                <item.icon
                                                                                    className={classNames(
                                                                                        item.current ? 'text-blue-600' : 'text-gray-400 group-hover:text-blue-600',
                                                                                        'h-6 w-6 shrink-0 ease-in-out duration-200'
                                                                                    )}
                                                                                    aria-hidden="true"
                                                                                />
                                                                                {item.name}
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                    </li>
                                                    <li className="mb-5">
                                                        <div className="flex items-center gap-x-4 p-2 text-sm font-semibold leading-6 text-gray-900">

                                                            <Switch
                                                                checked={enabled}
                                                                onChange={setEnabled}
                                                                className={classNames(
                                                                    enabled ? 'bg-blue-600' : 'bg-gray-200',
                                                                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
                                                                )}
                                                            >
                                                                <span className="sr-only">Use setting</span>
                                                                <span
                                                                    aria-hidden="true"
                                                                    className={classNames(
                                                                        enabled ? 'translate-x-5' : 'translate-x-0',
                                                                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                    )}
                                                                />
                                                            </Switch>
                                                            <span>Dark Mode</span>

                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
};
