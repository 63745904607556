import React from 'react';
import { Link } from 'react-router-dom';

export default function PassRecovery() {

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                    <h2 className="mt-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                        Recuperar contraseña
                    </h2>
                    <h3 className='mt-10 text-center text-xl font-normal leading-6 tracking-tight text-gray-900'>
                        Escribe tu dirección de Email, te enviaremos una contraseña provisoria para que puedas ingresar.</h3>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-lg">

                    <div className='mb-10'>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                value='email'
                                className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm"
                            />
                        </div>
                    </div>

                    <Link
                        to="/recuperarContraseñaCorreoEnviado"
                        className="w-full block text-center rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-5 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                    >
                        Enviar
                    </Link>

                </div>
            </div>
        </>
    );
}
