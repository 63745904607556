import React from 'react';
import { Link } from 'react-router-dom';

export default function InvestmentSurveyResult({ setIsAuthenticated }) {

    const loginRegister = () => {
        setIsAuthenticated(true);
        sessionStorage.setItem('isAuthenticated', 'true');
    }

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                    <h2 className="mt-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                    Felicitaciones!
                    </h2>
                </div>

                <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                    <p className="my-10 text-center text-sm text-gray-500">
                    Ahora puedes invertir directamente en las mejores administradoras de fondos del mercado. Te enviamos un email a tu correo con las copias de los contratos.
                    </p>

                    <Link
                        to="/"
                        onClick={loginRegister}
                        className="w-full block text-center rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-5 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                    >
                        Continuar
                    </Link>

                </div>
            </div>
        </>
    );
}
