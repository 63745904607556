import { Tooltip } from 'react-tooltip';
import {
    QuestionMarkCircleIcon
} from '@heroicons/react/24/solid';

const american = [
    { id: 'si', title: 'Si' },
    { id: 'no', title: 'No' }
]

const residentInOtherCountry = [
    { id: 'si', title: 'Si' },
    { id: 'no', title: 'No' }
]

const PoliticalExposurePerson = [
    { id: 'si', title: 'Si' },
    { id: 'no', title: 'No' }
]

export default function InvestmentSurveyStep5({ }) {

    return (
        <>
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                <h2 className="my-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                    información FATCA y PEP
                </h2>
                <h3 className='mb-10 text-center text-xl font-normal leading-6 tracking-tight text-gray-600'>
                    Es obligación de las instituciones financieras chilenas informar si tienes nacionalidad norteamericana y si eres una persona políticamente expuesta (o PEP).</h3>
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-lg mb-10">
                <div className='mb-5'>
                    <div className='flex justify-center gap-2'>
                        <label className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">¿Eres ciudadano norteamericano?</label>
                        <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="american" data-tooltip-content="Descripción del Input" />
                        <Tooltip id="american" />
                    </div>
                    <fieldset className="mt-4 flex justify-center">
                        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                            {american.map((radioAmerican) => (
                                <div key={radioAmerican.id} className="flex items-center">
                                    <input
                                        id={radioAmerican.id}
                                        name="american"
                                        type="radio"
                                        defaultChecked={radioAmerican.id === 'email'}
                                        className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                                    />
                                    <label htmlFor={radioAmerican.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                        {radioAmerican.title}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </fieldset>
                </div>
                <div className='mb-5'>
                    <div className='flex justify-center gap-2'>
                        <label className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">¿Tienes residencia tributaria en otro país?</label>
                        <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="resident" data-tooltip-content="Descripción del Input" />
                        <Tooltip id="resident" />
                    </div>
                    <fieldset className="mt-4 flex justify-center">
                        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                            {residentInOtherCountry.map((radioResident) => (
                                <div key={radioResident.id} className="flex items-center">
                                    <input
                                        id={radioResident.id}
                                        name="resident"
                                        type="radio"
                                        defaultChecked={radioResident.id === 'email'}
                                        className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                                    />
                                    <label htmlFor={radioResident.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                        {radioResident.title}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </fieldset>
                </div>
                <div className='mb-5'>
                    <div className='flex justify-center gap-2'>
                        <label className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">¿Eres una persona políticamente expuesta?</label>
                        <QuestionMarkCircleIcon className="min-h-6 h-6 min-w-6 w-6 text-gray-400 hover:text-blue-500  ease-in-out duration-200" aria-hidden="true" data-tooltip-id="exposure" data-tooltip-content="Descripción del Input" />
                        <Tooltip id="exposure" />
                    </div>
                    <fieldset className="mt-4 flex justify-center">
                        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                            {PoliticalExposurePerson.map((radioExposure) => (
                                <div key={radioExposure.id} className="flex items-center">
                                    <input
                                        id={radioExposure.id}
                                        name="exposure"
                                        type="radio"
                                        defaultChecked={radioExposure.id === 'email'}
                                        className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                                    />
                                    <label htmlFor={radioExposure.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                        {radioExposure.title}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </fieldset>
                </div>
            </div>
        </>
    );
}
