import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom"
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import RetirementForm from '../components/objectivesForms/RetirementForm';
import OthersForm from '../components/objectivesForms/OthersForm';
import HousingForm from '../components/objectivesForms/HousingForm';
import EducationForm from '../components/objectivesForms/EducationForm';
import FamilyProtectionForm from '../components/objectivesForms/FamilyProtectionForm';
import TravelForm from '../components/objectivesForms/TravelForm';
import OccasionsForm from '../components/objectivesForms/OccasionsForm';
import RelevantPurchasesForm from '../components/objectivesForms/RelevantPurchasesForm';
import RiskToleranceButton from '../components/common/RiskToleranceButtons';

export default function ObjectiveLayout() {
    const { selectedObjective } = useParams();

    return (
        <main className="lg:pr-64">
            <div className="xl:pl-72">
                <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
                    <div className="py-16 sm:py-16 lg:py-16">
                        <div className='pr-48 sm:pr-0 lg:pr-48'>
                            <div className="mt-2 relative left-[-15px]">
                                <div className="inline-flex space-x-6">
                                    <Link
                                        to={'/objetivos'}>
                                        <ChevronLeftIcon className='text-blue-500 h-12 w-12' />
                                    </Link>
                                    <span className="rounded-full flex items-center px-6 bg-blue-600/10 px-3 py-1 text-sm font-semibold leading-6 text-blue-600 ring-1 ring-inset ring-blue-600/10">
                                        {selectedObjective === 'Jubilacion' && <p>Jubilación</p>}
                                        {selectedObjective === 'Otros' && <p>Otros</p>}
                                        {selectedObjective === 'Vivienda' && <p>Vivienda</p>}
                                        {selectedObjective === 'Educacion' && <p>Educación</p>}
                                        {selectedObjective === 'ProteccionFamilia' && <p>Protección Familia</p>}
                                        {selectedObjective === 'Viajes' && <p>Viajes</p>}
                                        {selectedObjective === 'Ocasiones' && <p>Ocasiones</p>}
                                        {selectedObjective === 'ComprasRelevantes' && <p>Compras Relevantes</p>}
                                    </span>
                                </div>
                            </div>
                            <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                Personaliza tu objetivo
                            </h1>
                            <div className='rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 h-1 w-24 mt-5'></div>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Nos sirve para mostrarte las mejores alternativas de acuerdo a tu perfil, preferencias y necesidades.
                            </p>
                        </div>

                        <div>
                            {selectedObjective === 'Jubilacion' && <RetirementForm />}
                            {selectedObjective === 'Otros' && <OthersForm />}
                            {selectedObjective === 'Vivienda' && <HousingForm />}
                            {selectedObjective === 'Educacion' && <EducationForm />}
                            {selectedObjective === 'ProteccionFamilia' && <FamilyProtectionForm />}
                            {selectedObjective === 'Viajes' && <TravelForm />}
                            {selectedObjective === 'Ocasiones' && <OccasionsForm />}
                            {selectedObjective === 'ComprasRelevantes' && <RelevantPurchasesForm />}
                        </div>

                        <RiskToleranceButton />

                        <div className="mt-10 pt-10 border-t border-gray-900/10 flex items-center justify-center gap-x-6">
                            <Link
                                to={'/selectorBusqueda'}
                                className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-8 py-4 text-lg font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                            >
                                Siguiente
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    )
}