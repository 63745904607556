// DesktopSidebar.js
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Switch } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function DesktopSidebar({ navigation, currentNavigation }) {
    const [enabled, setEnabled] = useState(false);

    return (
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r-2 border-blue-500 bg-[#FAFBFC] px-10">
                <div className="flex h-20 shrink-0 items-center">
                    <img
                        className="h-8 w-auto"
                        src={process.env.PUBLIC_URL + 'logo-bb.svg'}
                        alt="Blue Bridge"
                    />
                </div>
                <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul role="list" className="-mx-2 space-y-1">
                                {navigation.map((item, index) => (
                                    index !== navigation.length - 1 && (
                                        <li key={item.name}>
                                            <a
                                                href={item.href}
                                                className={classNames(
                                                    item.name === currentNavigation
                                                        ? 'bg-[#F0F4FA] text-blue-500 before:block before:absolute before:h-4 before:w-2 before:mt-1 before:t-0 before:l-0 before:bg-blue-500 before:rounded-tr-full before:rounded-br-full before:left-0'
                                                        : 'text-[#050624] hover:text-blue-500 hover:bg-[#F0F4FA]',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold ease-in-out duration-200'
                                                )}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        item.name === currentNavigation
                                                            ? 'text-blue-500'
                                                            : 'text-[#A4B4CB] group-hover:text-blue-500',
                                                        'h-6 w-6 shrink-0 ease-in-out duration-200'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </a>
                                        </li>
                                    )
                                ))}
                            </ul>
                        </li>
                        <li className="mt-auto">
                            <ul>
                                <li className="mb-3">
                                    {navigation.map((item, index) => (
                                        <React.Fragment key={item.name}>
                                            {index === navigation.length - 1 && (
                                                <ul>
                                                    <li>
                                                        <Link
                                                            to={item.href}
                                                            className={classNames(
                                                                item.current
                                                                    ? 'bg-gray-50 text-blue-600'
                                                                    : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold ease-in-out duration-200'
                                                            )}
                                                        >
                                                            <item.icon
                                                                className={classNames(
                                                                    item.current ? 'text-blue-600' : 'text-gray-400 group-hover:text-blue-600',
                                                                    'h-6 w-6 shrink-0 ease-in-out duration-200'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                            {item.name}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </li>
                                <li className="mb-5">
                                    <div className="flex items-center gap-x-4 p-2 text-sm font-semibold leading-6 text-gray-900">

                                        <Switch
                                            checked={enabled}
                                            onChange={setEnabled}
                                            className={classNames(
                                                enabled ? 'bg-blue-600' : 'bg-gray-200',
                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
                                            )}
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    enabled ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                )}
                                            />
                                        </Switch>
                                        <span>Dark Mode</span>

                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}
