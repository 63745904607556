// App.js
import React from 'react';
import Layout from './layouts/Layout';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate} from 'react-router-dom';

const App = () => {
  return (
    <Router>
    <Layout />
    </Router>
  );
};

export default App;
