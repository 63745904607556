import React from 'react';
import { Link } from 'react-router-dom';

export default function PassRecoveryResult() {

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-lg">

                    <h3 className='mt-10 text-center text-xl font-normal leading-6 tracking-tight text-gray-900'>
                    Hemos enviado un link a tu correo con una clave provisoria
                    </h3>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-lg">

                    <h3 className='mb-10 font-light text-2xl text-center text-blue-400 leading-9'>

                        ******gr@gmail.com
                    </h3>

                    <Link
                        to="/login"
                        className="w-full block text-center rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-5 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                    >
                        Cerrar
                    </Link>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        ¿Aún no recibes el código? {' '}
                        <Link
                            to="/recuperarContraseñaCorreoEnviado"
                            className="font-semibold leading-6 text-blue-500 hover:text-blue-500"
                        >
                            Enviar código nuevamente
                        </Link>
                    </p>
                </div>
            </div>
        </>
    );
}
