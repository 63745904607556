function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Tables({ tableInfo, tableStatuses }) {

    return (
        <div className="py-10">
            <h3 className="text-xl text-[#050624] font-bold leading-6">Fondos Mutuos</h3>
            <table className="mt-6 w-full whitespace-nowrap text-left">
                <colgroup>
                    <col className="w-full sm:w-4/12" />
                    <col className="lg:w-4/12" />
                    <col className="lg:w-2/12" />
                    <col className="lg:w-1/12" />
                    <col className="lg:w-1/12" />
                </colgroup>
                <thead className="border-b border-gray/10 text-sm leading-6 text-slate-400">
                    <tr>
                        <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                            May 23, 2024
                        </th>
                        <th scope="col" className="py-2 pl-0 pr-8 font-semibold sm:table-cell">
                            Riesgo
                        </th>
                        <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                            %7d
                        </th>
                        <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                            %30d
                        </th>
                        <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                            %90d
                        </th>
                        <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                            %12m
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray/5 border-b border-gray/10">
                    {tableInfo.map((item) => (
                        <tr key={item.risk}>
                            <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                                <div className="flex items-center gap-x-4">
                                    <img src={item.asset.imageUrl} alt="" className="h-8 w-8 rounded-lg border border-gray-200 bg-white" />
                                    <div className="truncate text-sm font-medium leading-6 text-gray-900">{item.asset.name}</div>
                                </div>
                            </td>
                            <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                                <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                    <div className={classNames(tableStatuses[item.risk], 'flex-none rounded-full p-1')}>
                                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                    </div>
                                    <div className="hidden text-gray-900 sm:block">{item.risk}</div>
                                </div>
                            </td>
                            <td className="py-4 pl-0 pr-4 text-sm text-right leading-6 text-gray-500 md:table-cell lg:pr-8">
                                {item.period7d}
                            </td>
                            <td className="py-4 pl-0 pr-4 text-sm text-right leading-6 text-gray-500 md:table-cell lg:pr-8">
                                {item.period30d}
                            </td>
                            <td className="py-4 pl-0 pr-4 text-sm text-right leading-6 text-gray-500 md:table-cell lg:pr-8">
                                {item.period90d}
                            </td>
                            <td className="py-4 pl-0 pr-4 text-sm text-right leading-6 text-gray-500 md:table-cell lg:pr-8">
                                {item.period12m}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}