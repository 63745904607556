import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const formatYAxisTick = (value) => {
  if (value >= 1000) {
    return `${value / 1000}k`;
  }
  return value;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-md bg-blue-500 text-white font-bold py-2 px-4">
        <p className="label">{`$${payload[0].value.toLocaleString()}`}</p>
      </div>
    );
  }
  return null;
};

export default function AreaChartBB({ dataAreaChart }) {
  return (
    <ResponsiveContainer  width='100%' maxHeight={ 300 } className="-ml-5">
      <AreaChart
        data={dataAreaChart}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="5 5" vertical={false} stroke='#D3E1F5'/>
        <XAxis dataKey="name" axisLine={false} tickLine={false} tick={{fontSize: 12, fill: '#94a3b8'}}/>
        <YAxis  tickFormatter={formatYAxisTick} axisLine={false} tickLine={false} tick={{fontSize: 12, fill: '#94a3b8'}}/>
        <Tooltip content={<CustomTooltip />}
        cursor={{ stroke: 'rgba(0, 0, 0, 0.1)', strokeWidth: 2 }}/>

        {/* Definir gradiente del Area */}
        <defs>
          <linearGradient id="areaGradiant" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#0ea5e9" stopOpacity={1} />
            <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
          </linearGradient>
        </defs>
        {/* Definir gradiente del Stroke*/}
        <defs>
          <linearGradient id="strokeGradiant" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor="#22d3ee" stopOpacity={1} />
            <stop offset="95%" stopColor=" #0ea5e9" stopOpacity={1} />
          </linearGradient>
        </defs>

        <Area
          type="monotone"
          dataKey="uv"
          stroke="url(#strokeGradiant)"
          strokeWidth={3}  // Ajustar grosor de la línea
          fill="url(#areaGradiant)"  // Usar el gradiente
        />
      </AreaChart>
      </ResponsiveContainer>
  );
}
