function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function InvestmentSurveyStep2({ }) {

    return (
        <>
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                <h2 className="my-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                    ¿Casado (a) o tienes un acuerdo de unión civil?
                </h2>
                <h3 className='mb-10 text-center text-xl font-normal leading-6 tracking-tight text-gray-600'>
                    Necesitamos saber datos básicos de tu cónyuge, es sólo por fines regulatorios (podría ser una persona políticamente expuesta por ejemplo).</h3>
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-lg mb-10">
                <div className="sm:gap-4 sm:pb-2">
                    <label htmlFor="civilStatus" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Estado Civil
                    </label>
                    <div className="mt-1 gap-2">
                        <select
                            id="civilStatus"
                            name="civilStatus"
                            autoComplete="civilStatus"
                            className="block w-full h-9 rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        >
                            <option value="1">Soltero/a</option>
                            <option value="2">Casado/a</option>
                            <option value="3">Divorciado/a</option>
                            <option value="4">Viudo/a</option>
                            <option value="5">Separado/a</option>
                            <option value="6">Conviviente civil</option>
                            <option value="7">Conviviente no civil</option>

                        </select>
                    </div>
                </div>
                <div className="sm:gap-4 sm:pb-2">
                    <label htmlFor="maritalRegime" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Regimen Conyugal
                    </label>
                    <div className="mt-1 gap-2">
                        <select
                            id="maritalRegime"
                            name="maritalRegime"
                            autoComplete="maritalRegime"
                            className="block w-full h-9 rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        >
                            <option value="1">Separación total de bienes</option>
                            <option value="2">Participación en los gananciales</option>
                            <option value="3">Sociedad conyugal</option>
                            <option value="4">No sabe / No responde</option>

                        </select>
                    </div>
                </div>
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="nameSpouse" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Nombre del Conyuge
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="nameSpouse"
                            id="nameSpouse"
                            autoComplete="nameSpouse"
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="spuoseFatherLastName" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Apellido Paterno Conyuge
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="spuoseFatherLastName"
                            id="spuoseFatherLastName"
                            autoComplete="spuoseFatherLastName"
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="spuoseMotherLastName" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Apellido Materno Conyuge
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="spuoseMotherLastName"
                            id="spuoseMotherLastName"
                            autoComplete="spuoseMotherLastName"
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="rut" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        RUT
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="rut"
                            id="rut"
                            autoComplete="rut"
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Emails
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="email"
                            id="email"
                            autoComplete="email"
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

            </div>
        </>
    );
}
