import { Link } from "react-router-dom";
import ObjectiveHeader from "../components/common/ObjectiveHeader";
import Tables from "../components/common/Tables";
import TitleStats from "../components/common/TitlesStats";
import FlyoutMenu from "../components/common/FlyoutMenu";
import AreaChartBB from "../components/charts/AreaChartBB";
import ActiveShapePieChart from "../components/charts/ActiveShapePieChart";

const tableStatuses = { Bajo: 'text-lime-500 bg-lime-400/30', Medio: 'text-blue-500 bg-blue-400/30', Alto: 'text-rose-500 bg-rose-400/30' };

const tableInfo = [
  {
    asset: {
      name: 'Security Plus',
      imageUrl:
        require('../assets/invest-logos/2.jpg'),
    },
    risk: 'Bajo',
    period7d: '0.9%',
    period30d: '2.4%',
    period90d: '6.7%',
    period12m: '9.7%',
  },
  {
    asset: {
      name: 'Acciones USA',
      imageUrl:
        require('../assets/invest-logos/1.jpg'),
    },
    risk: 'Medio',
    period7d: '0.9%',
    period30d: '2.4%',
    period90d: '6.7%',
    period12m: '9.7%',
  },
  {
    asset: {
      name: 'Fondo Acciones Globales',
      imageUrl:
        require('../assets/invest-logos/1.jpg'),
    },
    risk: 'Alto',
    period7d: '0.9%',
    period30d: '2.4%',
    period90d: '6.7%',
    period12m: '9.7%',
  },
  {
    asset: {
      name: 'Fondo Renta Fija Largo Plazo',
      imageUrl:
        require('../assets/invest-logos/2.jpg'),
    },
    risk: 'Bajo',
    period7d: '0.9%',
    period30d: '2.4%',
    period90d: '6.7%',
    period12m: '9.7%',
  },
  {
    asset: {
      name: 'Fondo Acciones USA',
      imageUrl:
        require('../assets/invest-logos/1.jpg'),
    },
    risk: 'Bajo',
    period7d: '0.9%',
    period30d: '2.4%',
    period90d: '6.7%',
    period12m: '9.7%',
  },
  {
    asset: {
      name: 'Bci Competitivo',
      imageUrl:
        require('../assets/invest-logos/1.jpg'),
    },
    risk: 'Medio',
    period7d: '0.9%',
    period30d: '2.4%',
    period90d: '6.7%',
    period12m: '9.7%',
  },
];

const objective = [
  {
    name: 'Jubilación',
    riskTolerance: 'Baja',
    imageUrl: require('../assets/objective/1.jpg'),
  }
];

const titleStats = [
  { name: 'Valor Cuota', stat: '$71.897', previousStat: '$70.946', change: '12%', changeType: 'increase' }
];

const titleStatsComposite = [
  { name: 'Composición', stat: '$23.581.897', previousStat: '$21.321.157', change: '20%', changeType: 'increase' }
];

const chartMenuFilters = [
  { name: 'Diario', href: '#', period: '%1d' },
  { name: 'Semanal', href: '#', period: '%7d' },
  { name: 'Mensual', href: '#', period: '%30d' },
  { name: 'Trimestral', href: '#', period: '%90d' },
  { name: 'Anual', href: '#', period: '%12m' },
];


const dataAreaChart = [
  {
    name: 'Dec 2',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Dec 3',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Dec 4',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Dec 5',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Dec 6',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Dec 7',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Dec 8',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Dec 9',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Dec 10',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Dec 11',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Dec 12',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];


const investmentCompositeData = [
  { name: 'Jubilación', value: 5423658, fill: '#3b82f6' },
  { name: 'Auto', value: 2516511, fill: '#e11d48' },
  { name: 'Casa', value: 12586954, fill: '#84cc16' },
  { name: 'Viaje', value: 3054774, fill: '#eab308' },
];


export default function ManualSearchResult() {


  return (
    <main className="lg:pr-64">
      <div className="xl:pl-72">
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
          <div className="py-16 sm:py-16 lg:py-16">
            <ObjectiveHeader objective={objective[0]} />
            <Tables tableInfo={tableInfo} tableStatuses={tableStatuses} />

            <div className=" grid grid-cols-1 gap-5 sm:grid-cols-2">

              <div className="py-5 rounded-xl border-2 border-slate-200 bg-slate-100/30">
                <div className="px-5 flex items-start justify-between">
                  <TitleStats titleStats={titleStats} />
                  <FlyoutMenu chartMenuFilters={chartMenuFilters} />
                </div>
                <AreaChartBB dataAreaChart={dataAreaChart} />
              </div>
              <div className="py-5 rounded-xl border-2 border-slate-200 bg-slate-100/30">
                <div className="px-5 flex items-start justify-between">
                  <TitleStats titleStats={titleStatsComposite} />
                </div>
                <ActiveShapePieChart data={investmentCompositeData} />
              </div>
            </div>
            <div className="mt-10 pt-10 border-t border-gray-900/10 flex items-center justify-center gap-x-6">
              <Link
                to={'/'}
                className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-8 py-4 text-lg font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
              >
                Búsqueda Manual
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}