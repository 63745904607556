import { Link } from 'react-router-dom';
import Attachment2Icon from 'remixicon-react/Attachment2Icon'
import cedNew from '../../assets/cedula-nueva.svg'
import cedOld from '../../assets/cedula-antigua.svg'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const handleDownload = () => {
    // URL del archivo PDF local
    const pdfUrl = `${process.env.PUBLIC_URL}/dummy.pdf`;

    // Crea un enlace temporal
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'sample.pdf'; // Nombre de archivo para la descarga
    document.body.appendChild(link);

    // Simula un clic en el enlace para iniciar la descarga
    link.click();

    // Limpia el enlace temporal
    document.body.removeChild(link);
};

export default function InvestmentSurveyStep7({ }) {

    return (
        <>
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                <h2 className="my-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                    Firma Digital
                </h2>
                <h3 className='mb-10 text-center text-xl font-normal leading-6 tracking-tight text-gray-600'>
                    Se valida tu identidad con tu carnet de identidad y firmas digitalmente los contratos con las administradoras</h3>
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-lg mb-10">
                <div className="flex gap-4 w-full grid grid-cols-2 mb-10">
                    <div>
                        <label htmlFor="ci" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                            Tipo de Cédula
                        </label>
                        <div className='grid grid-cols-2 gap-4'>
                            <div className='block text-center'>
                                <img src={cedNew} className='' />
                                <p className='text-xs mt-1'>Nueva</p>
                            </div>
                            <div className='block text-center'>
                                <img src={cedOld} className='' />
                                <p className='text-xs mt-1'>Antigua</p>
                            </div>
                        </div>

                    </div>
                    <div className=" sm:gap-4 sm:pb-2">
                        <label htmlFor="serialNumber" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                            Número de Serie
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="serialNumber"
                                id="serialNumber"
                                autoComplete="serialNumber"
                                className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                            />
                        </div>
                        <p className="mt-2 text-xs text-gray-500 text-justify" id="email-description">
                            En cédulas de identidad nuevas el número de documento se encuentra en la parte frontal del documento. En cédulas antiguas este se encuentra al reverso.
                        </p>
                    </div>
                </div>
                <div className="flex gap-4 w-full grid grid-cols-2">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                            Foto frontal cédula de identidad
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <Attachment2Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Subir Archivo"
                            />
                        </div>
                        <p className="mt-2 text-xs text-gray-500 text-justify" id="email-description">
                            Formatos soportados: .jpg, .jpeg
                        </p>
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                            Foto trasera cédula de identidad
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <Attachment2Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Subir Archivo"
                            />
                        </div>
                        <p className="mt-2 text-xs text-gray-500 text-justify" id="email-description">
                            Formatos soportados: .jpg, .jpeg
                        </p>
                    </div>

                    <p className="mt-10 text-center col-span-2 text-sm text-gray-500">
                        Prefiero{' '}

                        <Link
                            to="/registro"
                            className="font-semibold leading-6 text-blue-500 hover:text-blue-500"
                        >
                            usar la cámara
                        </Link>
                        {' '}para tomar las fotos de mi cédula.
                    </p>
                </div>
            </div>
        </>
    );
}
