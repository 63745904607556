import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

const searchBy = [
    {
        id: 1,
        type: 'Para mis objetivos',
        description: 'Enfocamos la búsqueda al perfil de tus metas',
        url: '/busquedaSugerida',
        imageUrl:
            require('../assets/search-type/1.jpg'),
    },
    {
        id: 2,
        type: 'Búsqueda manual',
        description: 'Compara entre más de 120 alternativas disponibles. Agrega tu elección al objetivo que prefieras.',
        url: '/busquedaManual',
        imageUrl:
            require('../assets/search-type/2.jpg'),
    }
]

export default function SearchSelector() {
    const [selectedSearch, setSelectedSearch] = useState('');

    const handleSearchSelection = (objective) => {
        setSelectedSearch(objective);
    };

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <main className="lg:pr-64">
            <div className="xl:pl-72">
                <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
                    <div className="py-16 sm:py-16 lg:py-16">

                        <div className="text-center">
                            <div className="text-left mt-2 relative left-[-15px]">
                                <div className="inline-flex space-x-6">
                                    <button
                                        onClick={handleGoBack}>
                                        <ChevronLeftIcon className='text-blue-500 h-12 w-12' />
                                    </button>
                                </div>
                            </div>
                            <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                ¿Como quieres buscar?
                            </h1>
                            <div className='mx-auto rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 h-1 w-24 mt-5'></div>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Puedes buscar manualmente o utilizar nuestra búsqueda asistida
                            </p>

                            <div className="mt-12 grid grid-cols-1 gap-4 sm:grid-cols-4">

                                {searchBy.map((search) => (
                                    <div
                                        key={search.id}
                                        className="col-start-2 col-span-2 relative flex items-start space-x-3 rounded-lg border border-gray-300 group bg-white focus-within:bg-blue-500 hover:bg-blue-500 focus-within:drop-shadow-xl hover:drop-shadow-xl ease-in-out duration-200"
                                    >
                                        <div className="flex-shrink-0">
                                            <img className="h-32 w-28 rounded-tl-lg rounded-bl-lg" src={search.imageUrl} alt="" />
                                        </div>
                                        <div className="min-w-0 flex-1 p-2 flex justify-start">
                                            <button onClick={() => handleSearchSelection(search.url)} className="text-left focus:outline-none">
                                                <span className="absolute inset-0" aria-hidden="true" />
                                                <p className="text-xl font-medium font-bold text-blue-500 group-focus-within:text-white group-hover:text-white mb-2 ease-in-out duration-200">{search.type}</p>
                                                <p className="text-sm text-gray-500 group-focus-within:text-white group-hover:text-white ease-in-out duration-200">{search.description}</p>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="mt-10 pt-10 border-t border-gray-900/10 flex items-center justify-center gap-x-6">
                                <Link
                                    to={`${selectedSearch}`}
                                    className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-8 py-4 text-lg font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                >
                                    Siguiente
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
};
