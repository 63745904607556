import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/20/solid';
import SurveyRadio from './surveySteps/SurveyRadio';
import SurveyDatepicker from './surveySteps/SurveyDatepicker';

const steps = [
    { name: 'Step 1', href: '#', status: 'complete', component: Step1Form },
    { name: 'Step 2', href: '#', status: 'complete', component: Step2Form },
    { name: 'Step 3', href: '#', status: 'current', component: Step3Form },
    { name: 'Step 4', href: '#', status: 'upcoming', component: Step4Form },
    { name: 'Step 5', href: '#', status: 'upcoming', component: Step5Form },
    { name: 'Step 6', href: '#', status: 'upcoming', component: Step6Form },
    { name: 'Step 7', href: '#', status: 'upcoming', component: Step7Form }
];

const experience = {
    stepTitle: '¿Cual es tu experiencia con productos financieros?',
    settings: [
        { name: 'Poca experiencia', description: 'Poca, nunca he invertido en productos financieros' },
        { name: 'Experiencia moderada:', description: 'He invertido en productos como depósitos a plazo y Fondos Mutuos Balanceados' },
        { name: 'Experiencia considerable:', description: 'He invertido en acciones y en Fondos Mutuos accionarios internacionales' },
        { name: 'Alta experiencia:', description: 'He invertido en productos derivados (forward, simultáneas, notas estructuradas)' },
    ]
}

const investmentTerm = {
    stepTitle: 'El horizonte de inversión para mi objetivo más importante es:',
    settings: [
        { name: 'Corto plazo:', description: '6 meses o menos' },
        { name: 'Mediano plazo:', description: 'Entre 6 meses a 1 año' },
        { name: 'Largo plazo:', description: 'Entre 1 y 3 años' },
        { name: 'Muy largo plazo:', description: 'Más de 3 años' },
    ]
}

const investmentResponse = {
    stepTitle: '¿Qué harías si tus inversiones cayeran un 20%?',
    stepSubtitle: 'Por ejemplo como consecuencia de una crisis internacional',
    settings: [
        { name: 'Vendería todo', description: '' },
        { name: 'Vendería una parte', description: '' },
        { name: 'Esperaría unos días', description: '' },
        { name: 'Invertiría más para aprovechar la oportunidad', description: '' },
    ]
}

const riskTolerance = {
    stepTitle: 'Prefiero inversiones con baja rentabilidad pero segura',
    stepSubtitle: 'Elige la alternativa que más te represente',
    settings: [
        { name: 'Totalmente de acuerdo', description: '' },
        { name: 'De acuerdo la mayoría de las veces', description: '' },
        { name: 'En desacuerdo la mayoría de las veces', description: '' },
        { name: 'Totalmente en desacuerdo', description: '' },
    ]
}

const savingsCapacity = {
    stepTitle: '¿Cómo definirías tu capacidad de ahorro?',
    stepSubtitle: '',
    settings: [
        { name: 'Sin capacidad de ahorro', description: '' },
        { name: 'Puedo ahorrar hasta un 10% de mi ingreso mensual', description: '' },
        { name: 'Puedo ahorrar entre un 10% y un 25% de mi ingreso mensual', description: '' },
        { name: 'Puedo ahorrar más de un 25% de mi ingreso mensual', description: '' },
    ]
}

const employmentStatus = {
    stepTitle: 'Situación laboral',
    stepSubtitle: '',
    settings: [
        { name: 'Trabajador dependiente', description: '' },
        { name: 'Trabajador independiente', description: '' },
        { name: 'Empresario', description: '' },
        { name: 'Otros', description: '' },
    ]
}


function Step1Form() {
    // Implementa el formulario para el paso 1
    return <SurveyDatepicker/>;
}

function Step2Form() {
    // Implementa el formulario para el paso 2
    return <SurveyRadio settings={experience}/>;
}

function Step3Form() {
    // Implementa el formulario para el paso 1
    return <SurveyRadio settings={investmentTerm}/>;
}

function Step4Form() {
    // Implementa el formulario para el paso 2
    return <SurveyRadio settings={investmentResponse}/>;
}

function Step5Form() {
    // Implementa el formulario para el paso 1
    return <SurveyRadio settings={riskTolerance}/>;
}

function Step6Form() {
    // Implementa el formulario para el paso 2
    return <SurveyRadio settings={savingsCapacity}/>;
}

function Step7Form() {
    // Implementa el formulario para el paso 1
    return <SurveyRadio settings={employmentStatus}/>;
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Survey() {
    const [currentStep, setCurrentStep] = useState(0);

    const handleNextStep = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handlePrevStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleFinish = () => {
        // Aquí puedes implementar la lógica para finalizar el proceso de encuesta
        console.log('Encuesta finalizada');
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <nav aria-label="Progress" className='sm:mx-auto sm:w-full sm:max-w-lg flex justify-center'>
                <ol role="list" className="flex items-center">
                    {steps.map((step, stepIdx) => {
                        let status;
                        if (stepIdx < currentStep) {
                            status = 'complete';
                        } else if (stepIdx === currentStep) {
                            status = 'current';
                        } else {
                            status = 'upcoming';
                        }
                        return (
                            <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pr-4 sm:pr-8' : '', 'relative')}>
                                {status === 'complete' ? (
                                    <>
                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div className="h-0.5 w-full bg-blue-600" />
                                        </div>
                                        <a
                                            href="#"
                                            className="relative flex h-6 w-6 items-center justify-center rounded-full bg-blue-600 hover:bg-blue-900"
                                        >
                                            <CheckIcon className="h-3 w-3 text-white" aria-hidden="true" />
                                            <span className="sr-only">{step.name}</span>
                                        </a>
                                    </>
                                ) : status === 'current' ? (
                                    <>
                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div className="h-0.5 w-full bg-gray-200" />
                                        </div>
                                        <a
                                            href="#"
                                            className="relative flex h-6 w-6 items-center justify-center rounded-full border-2 border-blue-600 bg-white"
                                            aria-current="step"
                                        >
                                            <span className="h-2.5 w-2.5 rounded-full bg-blue-600" aria-hidden="true" />
                                            <span className="sr-only">{step.name}</span>
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div className="h-0.5 w-full bg-gray-200" />
                                        </div>
                                        <a
                                            href="#"
                                            className="group relative flex h-6 w-6 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                                        >
                                            <span
                                                className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                                                aria-hidden="true"
                                            />
                                            <span className="sr-only">{step.name}</span>
                                        </a>
                                    </>
                                )}
                            </li>
                        );
                    })}
                </ol>
            </nav>
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                {React.createElement(steps[currentStep].component)}
                <div className=' flex justify-between'>

                {currentStep === 0 && (
                    <button className="rounded-md px-5 py-2 text-sm font-semibold bg-gray/5 cursor-auto text-gray-300 ring-1 ring-gray-300">
                        Anterior
                    </button>
                )}
                {currentStep > 0 && (
                    <button className="rounded-md px-5 py-2 text-sm font-semibold text-blue-500 ring-1 ring-blue-500" onClick={handlePrevStep}>
                        Anterior
                    </button>
                )}
                {currentStep < steps.length - 1 && (
                    <button className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-5 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" onClick={handleNextStep}>
                        Siguiente
                    </button>
                )}
                {currentStep === steps.length - 1 && (
                    <Link to="/resultadoEncuesta" className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-5 py-2 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" onClick={handleFinish}>
                        Finalizar
                    </Link>
                )}
                </div>
            </div>
        </div>
    );
}

