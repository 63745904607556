export default function InvestmentStatus() {

    return (
        <div className='border-b border-gray-200 pb-5'>
            <div className="grid grid-cols-2 gap-4 mt-5">
                <div>
                    <div className='rounded-lg bg-[#FAFBFC] border-2 border-blue-500 py-4 px-4'>
                        <h4 className='text-[#A4B4CB] mb-1 text-base font-normal'>Total en Pesos</h4>
                        <div className='rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 h-1 w-16 mb-2'></div>
                        <h2 className='text-[#050624] text-3xl font-bold'>$0</h2>
                    </div>
                    <div className='rounded-lg bg-[#FAFBFC] border-2 border-blue-500 py-4 px-4 mt-2'>
                        <h4 className='text-[#A4B4CB] mb-1 text-base font-normal'>Inversiones Pendientes</h4>
                        <div className='rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 h-1 w-16 mb-2'></div>
                        <h2 className='text-[#050624] text-3xl font-bold'>$0</h2>
                    </div>
                </div>

                <div>
                    <div className='rounded-lg bg-[#FAFBFC] border-2 border-blue-500 py-4 px-4 h-full'>
                        <h4 className='text-[#A4B4CB] mb-1 text-base font-normal'>Inversiones Pendientes</h4>
                        <div className='rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 h-1 w-16 mb-2'></div>
                        <div className='text-[#A4B4CB] text-sm font-normal pl-5'>
                            <ul className='list-disc'>
                                <li>
                                    Opciones de fondos para rebalancear.
                                </li>
                                <li>
                                    Sugerencias de fondos, por rentabilidad, nuevos fondos.
                                </li>
                                <li>
                                    Alertas de mercado.
                                </li>
                                <li>
                                    Alerta de publicaciones de informes relevantes (banco central, informes de administradoras).
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}