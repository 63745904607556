function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function InvestmentSurveyStep4({ }) {

    return (
        <>
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                <h2 className="my-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
                    Datos de tu cuenta bancaria y origen de fondos
                </h2>
                <h3 className='mb-10 text-center text-xl font-normal leading-6 tracking-tight text-gray-600'>
                    Tu transfieres directo a las administradoras para invertir. Cuando rescatas tu dinero se deposita directa en la cuenta de tu banco.</h3>
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-lg mb-10">
                <div className="sm:gap-4 sm:pb-2">
                    <label htmlFor="bank" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Banco
                    </label>
                    <div className="mt-1 gap-2">
                        <select
                            id="bank"
                            name="bank"
                            autoComplete="bank"
                            className="block w-full h-9 rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        >
                            <option value="Banco de Chile">Banco de Chile</option>
                            <option value="Banco Estado">Banco Estado</option>
                            <option value="Banco Santander Chile">Banco Santander Chile</option>
                            <option value="Banco de Crédito e Inversiones (BCI)">Banco de Crédito e Inversiones</option>
                            <option value="Scotiabank Chile">Scotiabank Chile</option>
                            <option value="Banco Security">Banco Security</option>
                            <option value="Banco Falabella">Banco Falabella</option>
                            <option value="Banco Itaú Chile">Banco Itaú Chile</option>
                            <option value="Banco Ripley">Banco Ripley</option>
                            <option value="Banco Consorcio">Banco Consorcio</option>
                            <option value="Banco BICE">Banco BICE</option>
                            <option value="Banco Internacional">Banco Internacional</option>
                            <option value="Banco Corpbanca">Banco Corpbanca</option>
                            <option value="Banco Consorcio">Banco Consorcio</option>
                            <option value="Banco Security">Banco Security</option>
                            <option value="Banco Paris">Banco Paris</option>

                        </select>
                    </div>
                </div>
                <div className="sm:gap-4 sm:pb-2">
                    <label htmlFor="acountType" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Tipo de Cuenta
                    </label>
                    <div className="mt-1 gap-2">
                        <select
                            id="acountType"
                            name="acountType"
                            autoComplete="acountType"
                            className="block w-full h-9 rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        >
                            <option value="Cuenta Corriente">Cuenta Corriente</option>
                            <option value="Cuenta de Ahorro">Cuenta de Ahorro</option>
                            <option value="Cuenta Vista">Cuenta Vista</option>
                            <option value="Cuenta Rut">Cuenta Rut</option>
                            <option value="Cuenta de Cheques">Cuenta de Cheques</option>
                            <option value="Cuenta de Depósito a Plazo">Cuenta de Depósito a Plazo</option>
                            <option value="Cuenta de Remuneraciones">Cuenta de Remuneraciones</option>
                            <option value="Cuenta de Inversión">Cuenta de Inversión</option>
                            <option value="Cuenta Sueldo">Cuenta Sueldo</option>

                        </select>
                    </div>
                </div>
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="accountNumber" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Número de cuenta
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="accountNumber"
                            id="accountNumber"
                            autoComplete="accountNumber"
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="fundsOrigin" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Origen de los Fondos
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="fundsOrigin"
                            id="fundsOrigin"
                            autoComplete="fundsOrigin"
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="profesion" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Profesión
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="profesion"
                            id="profesion"
                            autoComplete="profesion"
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className=" sm:gap-4 sm:pb-2">
                    <label htmlFor="occupation" className="block text-sm font-medium leading-6 text-gray-500 sm:pt-1.5">
                        Ocupación
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="occupation"
                            id="occupation"
                            autoComplete="occupation"
                            className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-500 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-300 focus-visible:outline-none sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

            </div>
        </>
    );
}
