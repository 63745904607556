import ProfileHeader from '../components/common/ProfileHeader';
import Articles from '../blog/Articles';
import LastNews from '../blog/LastNews';
import MoreNews from '../blog/MoreNews';
import InvestmentStatus from '../components/InvestmentStatus';
import InvestmentObjectives from '../components/InvestmentObjectives';
import OtherInvestmentAssets from '../components/OtherInvestmentAssets';

const featuredArticle = {
    id: 4,
    title: 'Por qué son los bancos medianos los que están en el centro de la crisis financiera',
    href: '#',
    imageUrl: require('../assets/blog/4.jpg'),
    description:
        'Una mirada europea al mundo de la economía y los negocios. Hechos, análisis y las historias reales desde las principales plazas financieras, los mercados...',
    date: 'Mar 16, 2024',
    datetime: '03-12-2023',
    author: {
        name: 'Fernando Muñoz',
        href: '#',
        imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
};
const articles = [
    {
        id: 5,
        title: 'Cripto Boom Continúa',
        href: '#',
        description:
            'Las criptomonedas alcanzan nuevas alturas, desafiando expectativas. Inversionistas buscan oportunidades en este mercado volátil.',
        date: 'Septiembre 2023',
        datetime: '03-12-2023',
        author: {
            name: 'Finanzas Globales Today',
            href: '#',
            imageUrl:
                'https://img.freepik.com/vector-gratis/plantilla-logotipo-contabilidad-degradado_23-2148863564.jpg?size=626&ext=jpg&uid=R8114563&ga=GA1.1.59439108.1685040481&semt=ais',
        },
    },
    {
        id: 6,
        title: 'Recesión Evitada',
        href: '#',
        description:
            'Políticas económicas efectivas amortiguan impacto. Expertos prevén un repunte sostenido en los mercados internacionales.',
        date: 'Octubre 2023',
        datetime: '03-12-2023',
        author: {
            name: 'Perspectivas Económicas Magazine',
            href: '#',
            imageUrl:
                'https://img.freepik.com/vector-gratis/plantilla-logotipo-contabilidad-degradado_23-2148867917.jpg?size=626&ext=jpg&uid=R8114563&ga=GA1.1.59439108.1685040481&semt=ais',
        },
    },
    {
        id: 62,
        title: 'Recesión Evitada',
        href: '#',
        description:
            'Políticas económicas efectivas amortiguan impacto. Expertos prevén un repunte sostenido en los mercados internacionales.',
        date: 'Octubre 2023',
        datetime: '03-12-2023',
        author: {
            name: 'Perspectivas Económicas Magazine',
            href: '#',
            imageUrl:
                'https://img.freepik.com/vector-gratis/plantilla-logotipo-contabilidad-degradado_23-2148867917.jpg?size=626&ext=jpg&uid=R8114563&ga=GA1.1.59439108.1685040481&semt=ais',
        },
    },
];
const lastNews = [
    {
        id: 1,
        title: 'Las Startups Revolucionan Fintech',
        href: '#',
        description:
            'Nuevas empresas fintech transforman la industria financiera. Innovación y tecnología lideran el camino hacia el cambio.',
        imageUrl: require('../assets/blog/1.jpg'),
        date: 'Noviembre 2023',
        datetime: '03-12-2023',
        category: { title: 'Fintech', href: '#' },
        author: {
            name: 'TechFinance Insights',
            href: '#',
            imageUrl:
                'https://img.freepik.com/vector-gratis/plantilla-logotipo-contabilidad-degradado_23-2148865387.jpg?size=626&ext=jpg&uid=R8114563&ga=GA1.1.59439108.1685040481&semt=ais',
        },
    },
    {
        id: 2,
        title: 'Boom de Inversiones Sostenibles',
        href: '#',
        description:
            'Los inversionistas priorizan proyectos sostenibles. El mercado experimenta un cambio hacia prácticas más responsables.',
        imageUrl: require('../assets/blog/2.jpg'),
        date: 'Agosto 2023',
        datetime: '03-12-2023',
        category: { title: 'Inversiones', href: '#' },
        author: {
            name: 'Sostenibilidad Financiera Journal',
            href: '#',
            imageUrl:
                'https://img.freepik.com/vector-gratis/logotipo-grafico-abstracto_1043-36.jpg?t=st=1705004371~exp=1705004971~hmac=74c10d79d3abd8f7d5f398c88b25751eae78baa51d54facc1a5797283ea54333',
        },
    },
    {
        id: 3,
        title: 'Bancos Centrales Ajustan Tasas',
        href: '#',
        description:
            'Movimientos en las tasas de interés globales. Los bancos centrales responden a dinámicas económicas cambiantes.',
        imageUrl: require('../assets/blog/3.jpg'),
        date: 'Julio 2023',
        datetime: '05-07-2023',
        category: { title: 'Bancos', href: '#' },
        author: {
            name: 'Bancos y Finanzas Now',
            href: '#',
            imageUrl:
                'https://img.freepik.com/vector-gratis/plantilla-logotipo-contabilidad-degradado_23-2148857283.jpg?size=626&ext=jpg&uid=R8114563&ga=GA1.1.59439108.1685040481&semt=ais',
        },
    }
];

const moreNews = [
    {
        id: 3,
        title: 'La Nueva Normalidad Financiera',
        href: '#',
        description:
            'Las empresas adoptan modelos híbridos. La digitalización y el trabajo remoto transforman la cultura empresarial.',
        imageUrl: require('../assets/blog/5.jpg'),
        date: 'Diciembre 2023',
        datetime: '12-12-2023',
        category: { title: 'Empresas', href: '#' },
        author: {
            name: 'Finanzas en Transformación',
            href: '#',
            imageUrl:
            'https://img.freepik.com/vector-gratis/plantilla-logotipo-contabilidad-degradado_23-2148867917.jpg?size=626&ext=jpg&uid=R8114563&ga=GA1.1.59439108.1685040481&semt=ais',
        },
    },
    {
        id: 8,
        title: 'Crecimiento Explosivo en Startups',
        href: '#',
        description:
        'Las startups experimentan un crecimiento explosivo. Inversiones récord y adopción masiva de nuevas tecnologías impulsan la innovación en el mercado.',
        imageUrl: require('../assets/blog/6.jpg'),
        date: 'Enero 2024',
        datetime: '15-01-2024',
        category: { title: 'Startups', href: '#' },
        author: {
            name: 'TechInnovation Insights',
            href: '#',
            imageUrl:
            'https://img.freepik.com/vector-gratis/plantilla-logotipo-contabilidad-degradado_23-2148863565.jpg?size=626&ext=jpg&uid=R8114563&ga=GA1.1.59439108.1685040481&semt=ais',
        },
    },
    {
        id: 9,
        title: 'Estrategias para el Éxito Financiero',
        href: '#',
        description:
        'Descubre las estrategias clave para alcanzar el éxito financiero en el cambiante panorama económico. Consejos de expertos y análisis detallado.',
        imageUrl: require('../assets/blog/7.jpg'),
        date: 'Septiembre 2023',
        datetime: '22-09-2023',
        category: { title: 'Consejos Financieros', href: '#' },
        author: {
            name: 'Financial Wisdom',
            href: '#',
            imageUrl:
            'https://img.freepik.com/vector-premium/plantilla-diseno-logotipo-grafico-financiero-forma-cuadrada-flecha_698214-72.jpg?size=626&ext=jpg&uid=R8114563&ga=GA1.1.59439108.1685040481&semt=ais',
        },
    },
    {
        id: 10,
        title: 'Tendencias en Inversiones Globales',
        href: '#',
        description:
        'Analiza las últimas tendencias en inversiones a nivel mundial. Desde criptomonedas hasta bienes raíces, descubre dónde están poniendo su dinero los inversionistas.',
        imageUrl: require('../assets/blog/8.jpg'),
        date: 'Agosto 2023',
        datetime: '10-08-2023',
        category: { title: 'Inversiones', href: '#' },
        author: {
            name: 'Global Investment Insights',
            href: '#',
            imageUrl:
            'https://img.freepik.com/vector-gratis/logotipo-banca-plantilla-negocio-vector-diseno-marca_53876-136250.jpg?size=626&ext=jpg&uid=R8114563&ga=GA1.1.59439108.1685040481&semt=ais',
        },
    },
    {
        id: 11,
        title: 'Desafíos Económicos en el Horizonte',
        href: '#',
        description:
        'Explora los desafíos económicos que se avecinan en el horizonte. Factores geopolíticos, fluctuaciones del mercado y más impactan la estabilidad financiera global.',
        imageUrl: require('../assets/blog/9.jpg'),
        date: 'Julio 2023',
        datetime: '05-07-2023',
        category: { title: 'Economía Global', href: '#' },
        author: {
            name: 'Economic Insights Today',
            href: '#',
            imageUrl:
            'https://img.freepik.com/vector-premium/diseno-logotipo-contabilidad-moderna-finanzas-datos-crecimiento-creativo_467913-1058.jpg?size=626&ext=jpg&uid=R8114563&ga=GA1.1.59439108.1685040481&semt=ais',
        },
    },
    {
        id: 12,
        title: 'Nuevas Oportunidades en Tecnología',
        href: '#',
        description:
        'Descubre las oportunidades emergentes en el sector tecnológico. Desde inteligencia artificial hasta blockchain, las empresas están capitalizando las últimas innovaciones.',
        imageUrl: require('../assets/blog/10.jpg'),
        date: 'Junio 2023',
        datetime: '18-06-2023',
        category: { title: 'Tecnología', href: '#' },
        author: {
            name: 'TechOpportunities Now',
            href: '#',
            imageUrl:
                'https://img.freepik.com/vector-gratis/plantilla-logotipo-contabilidad-degradado_23-2148863564.jpg?size=626&ext=jpg&uid=R8114563&ga=GA1.1.59439108.1685040481&semt=ais',
        },
    },
];

export default function Home({ user }) {

    return (
        <main className="lg:pr-64 lg:pl-72">
                <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
                    <div className="py-16 sm:py-16 lg:py-16">

                        <ProfileHeader user={user} />

                        <InvestmentStatus />

                        <InvestmentObjectives />

                        <OtherInvestmentAssets />

                        <Articles featuredArticle={featuredArticle} articles={articles} />
                        <LastNews lastNews={lastNews} />
                        <MoreNews moreNews={moreNews} />
                    </div>
                </div>
        </main>
    )
};
