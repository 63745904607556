import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import CarLineIcon from 'remixicon-react/CarLineIcon';
import BankLineIcon from 'remixicon-react/BankLineIcon';
import Home8LineIcon from 'remixicon-react/Home8LineIcon';
import FundsLineIcon from 'remixicon-react/FundsLineIcon';
import StockLineIcon from 'remixicon-react/StockLineIcon';
import HandCoinLineIcon from 'remixicon-react/HandCoinLineIcon';
import EarthLineIcon from 'remixicon-react/EarthLineIcon';
import ExchangeDollarLineIcon from 'remixicon-react/ExchangeDollarLineIcon';


const addOtherAssets = [
    {
        id: 1,
        asset: 'Fondos AFP',
        description: 'Ahorro obligatorio, APV, inversiones en Cuenta 2',
        form: 'FondosAFP',
        icon: BankLineIcon,
    },
    {
        id: 2,
        asset: 'Vehículos',
        description: 'Tu auto, moto u otro vehículo que tengas',
        form: 'Vehiculos',
        icon: CarLineIcon,
    },
    {
        id: 3,
        asset: 'Propiedades',
        description: 'Tu hogar, inversiones en departamentos, segunda vivienda, terrenos',
        form: 'Propiedades',
        icon: Home8LineIcon,
    },
    {
        id: 4,
        asset: 'Acciones',
        description: 'De empresas cotizadas en bolsa o sociedades cerradas',
        form: 'Acciones',
        icon: StockLineIcon,
    },
    {
        id: 5,
        asset: 'Fondos mutuos',
        description: 'Fondos mutuos o de inversión',
        form: 'FondosMutuos',
        icon: HandCoinLineIcon,
    },
    {
        id: 6,
        asset: 'Inversiones exterior',
        description: 'Cuentas de inversión en el exterior',
        form: 'InversionesExterior',
        icon: EarthLineIcon,
    },
    {
        id: 7,
        asset: 'Dólares',
        description: 'O cualquier inversión en monedas extranjeras ',
        form: 'Dolares',
        icon: ExchangeDollarLineIcon,
    },
    {
        id: 8,
        asset: 'Otros',
        description: 'Destina recursos para tener más tranquilidad en el futuro',
        form: 'Otros',
        icon: FundsLineIcon,
    },
]

export default function SuggestedSearch() {

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <main className="lg:pr-64">
            <div className="xl:pl-72">
                <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
                    <div className="py-16 sm:py-16 lg:py-16">

                        <div className="text-center">
                            <div className="text-left mt-2 relative left-[-15px]">
                                <div className="inline-flex space-x-6">
                                    <button
                                        onClick={handleGoBack}>
                                        <ChevronLeftIcon className='text-blue-500 h-12 w-12' />
                                    </button>
                                </div>
                            </div>
                            <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                Elige la categoría de activo
                            </h1>
                            <div className='mx-auto rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 h-1 w-24 mt-5'></div>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Elije la alternativa que represente mejor la clase de activo
                            </p>

                            <div className="mt-12 grid grid-cols-1 gap-4 sm:grid-cols-2">
                                {addOtherAssets.map((asset) => (
                                    <div
                                        key={asset.id}
                                        className="relative p-4 flex items-center space-x-3 rounded-lg border border-gray-300 group bg-white focus-within:bg-blue-500 hover:bg-blue-500 focus-within:drop-shadow-xl hover:drop-shadow-xl ease-in-out duration-200"
                                    >
                                        <div className="flex h-20 w-20 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-gray-50/5 ease-in-out duration-200">
                                            <asset.icon className="h-12 w-12 text-gray-500 group-hover:text-white ease-in-out duration-200 fa-inverse" aria-hidden="true" />
                                        </div>
                                        <div className="min-w-0 flex-1 p-2 flex justify-start">
                                            <button className="text-left focus:outline-none">
                                                <span className="absolute inset-0" aria-hidden="true" />
                                                <p className="text-xl font-medium font-bold text-blue-500 group-focus-within:text-white group-hover:text-white mb-2 ease-in-out duration-200">{asset.asset}</p>
                                                <p className="text-sm text-gray-500 group-focus-within:text-white group-hover:text-white ease-in-out duration-200">{asset.description}</p>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="mt-10 pt-10 border-t border-gray-900/10 flex items-center justify-center gap-x-6">
                                <Link
                                    to={'/'}
                                    className="rounded-md bg-gradient-to-r from-blue-500 to-cyan-400 px-8 py-4 text-lg font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                >
                                    Siguiente
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
};
